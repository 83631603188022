<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    width="wide"
    :closeable="!isDeploying"
    :subject="logTitle"
    data-automation="bundle-logs-modal"
    @close="$emit('close')"
  >
    <template #content>
      <LogViewer
        v-if="isDeploying || finishedDeploying"
        :entries="deployLogs"
        :embedded="false"
        class="output"
      />
      <LogOutput
        v-else-if="jobs.length > 0"
        :app="app"
        :job="currentJob"
        class="output"
      />
    </template>
    <template #controls>
      <div class="actions flex">
        <div class="actionBar showTitles alignWithRegularButtons">
          <button
            v-if="previousVisible"
            class="action back"
            :disabled="!previousEnabled"
            data-automation="previous-log"
            @click="prevJob"
          >
            Previous Activation
          </button>
          <button
            v-if="nextVisible"
            class="action forward"
            :disabled="!nextEnabled"
            data-automation="next-log"
            @click="nextJob"
          >
            Next
          </button>
        </div>
        <a
          v-if="downloadVisible"
          class="button default"
          :href="downloadUrl"
          :class="{ disabled: !downloadEnabled }"
          data-automation="log-download"
        >Download Log</a>
      </div>
    </template>
  </RSModal>
</template>

<script>
import { downloadJobLogPath, getJobLog } from '@/api/jobs';
import LogViewer from '@/components/LogViewer';
import RSModal from '@/elements/RSModal.vue';
import {
  SET_BUNDLE_LOG_POSITION,
} from '@/store/modules/bundles';
import LogOutput from '@/views/content/LogsOverlay/LogOutput';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'BundleLog',
  components: { RSModal, LogOutput, LogViewer },
  props: {
    jobs: {
      type: Array,
      default: () => []
    }
  },
  emits: ['close'],
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      isFetchingBundles: state => state.bundles.isFetchingBundles,
      isFetchingBundleJobs: state => state.bundles.isFetchingBundleJobs,
      currentJobKey: state => state.bundles.selected.currentJobKey,
      deployLogs: state => state.bundles.selected.deployLogs,
      isDeploying: state => state.bundles.isDeploying,
    }),
    jobIndex() {
      return this.jobs.findIndex(j => j.key === this.currentJobKey);
    },
    currentJob() {
      return this.jobs[this.jobIndex];
    },
    previousVisible() {
      return !this.isDeploying && this.jobs.length > 1;
    },
    previousEnabled() {
      return this.jobIndex < this.jobs.length - 1;
    },
    nextVisible() {
      return !this.isDeploying && this.jobs.length > 1;
    },
    nextEnabled() {
      return this.jobIndex > 0;
    },
    downloadVisible() {
      return !this.isDeploying && !this.finishedDeploying && this.jobs.length > 0;
    },
    downloadEnabled() {
      return this.jobs.length > 0;
    },
    downloadUrl() {
      return this.app.guid && this.currentJobKey ? downloadJobLogPath(this.app.guid, this.currentJobKey) : '';
    },
    isFetchingData() {
      return this.isFetchingBundleJobs || this.isFetchingBundles;
    },
    finishedDeploying() {
      return !this.isDeploying && this.deployLogs.length;
    },
    logTitle() {
      if (this.isFetchingData) {
        return 'Loading...';
      }
      if (this.currentJobKey === false) {
        return 'No logs found.';
      }

      if (this.jobs.length === 0 || this.jobIndex === -1) {
        return 'Log not found.';
      }

      if (this.isDeploying) {
        return 'Activating';
      }

      if (this.finishedDeploying) {
        return 'Activation complete.';
      }

      return `Activation log for version #${this.currentJob.bundleId}: ${this.currentJob.key}`;
    },
  },
  methods: {
    ...mapMutations({
      setBundleLogPosition: SET_BUNDLE_LOG_POSITION,
    }),
    nextJob() {
      const { key } = this.jobs[this.jobIndex - 1];
      this.setBundleLogPosition(key);
      getJobLog(this.app.guid, key);
    },
    prevJob() {
      const { key } = this.jobs[this.jobIndex + 1];
      this.setBundleLogPosition(key);
      getJobLog(this.app.guid, key);
    },
  }
};
</script>

<style lang="scss" scoped>
.output {
  height: 60vh;
  text-align: left;
}

.back {
  background-image: url(Images/elements/actionBack.svg);
}

.forward {
    background-image: url(Images/elements/actionForward.svg);
  }
</style>
