<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<script setup>
import AppModes from '@/api/dto/appMode';
import { computed } from 'vue';
import { useStore } from 'vuex';

const contentDescriptions = {
  [AppModes.JupyterVoila]:  'a Voilà interactive notebook',
  [AppModes.PlumberApi]:  'an API-hosting application',
  [AppModes.PythonApi]:  'an API-hosting application',
  [AppModes.PythonBokeh]:  'an interactive Bokeh application',
  [AppModes.PythonDash]:  'an interactive Dash application',
  [AppModes.PythonGradio]:  'a Gradio application',
  [AppModes.PythonFastAPI]:  'a FastAPI application',
  [AppModes.PythonShiny]:  'an interactive Shiny document',
  [AppModes.PythonStreamlit]:  'an interactive Streamlit application',
  [AppModes.ShinyQuarto]:  'an interactive Shiny document',
  [AppModes.ShinyRmd]: 'an interactive Shiny document',
  [AppModes.Shiny]: 'an interactive Shiny application',
  [AppModes.StaticJupyter]:  'a Jupyter notebook',
  [AppModes.StaticQuarto]:  'Quarto content',
  [AppModes.StaticRmd]: 'an R Markdown document',
  [AppModes.Static]: 'a document',
  [AppModes.Proxied]: 'proxied content',
  [AppModes.TensorFlowApi]:  'a TensorFlow Model API',
  [AppModes.Unknown]: 'incomplete content',
};

const store = useStore();

const app = computed(() => store.state.contentView.app);
const isWorker = computed(() => app.value.hasWorker());
const isStatic = computed(() => app.value.isStatic());
const isProxied = computed(() => app.value.isProxied());
const isUnknown = computed(() => app.value.appMode === AppModes.Unknown);
const noSchedulableAppDesc = computed(() => contentDescriptions[app.value.appMode]);
</script>

<template>
  <div
    class="formSection infoSection"
    data-automation="schedule-tab__not-schedulable"
  >
    <p
      v-if="isWorker"
      data-automation="schedule-tab__not-schedulable-worker"
    >
      This is
      <span>{{ noSchedulableAppDesc }}</span>
      so there is no need to render on a schedule - it will present the latest data whenever it is run.
    </p>

    <p
      v-if="isStatic"
      data-automation="schedule-tab__not-schedulable-static"
    >
      The source code for this content was not published. The output cannot be refreshed.
    </p>
    <p
      v-if="isProxied"
      data-automation="schedule-tab__not-schedulable-proxied"
    >
      This content cannot be refreshed.
    </p>
    <p
      v-if="isUnknown"
      data-automation="schedule-tab__not-schedulable-unknown"
    >
      This content cannot be refreshed.
    </p>
  </div>
</template>
