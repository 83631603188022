<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Open Solo (opens a new window)"
    title="Open Solo"
    class="action content-actions__open-solo-button"
    :class="classes"
    :data-automation="dataAutomation"
    :disabled="!serverSettings"
    @click="runAction"
  >
    <span
      v-if="dropdown"
      class="actionTitle"
    >
      Open Solo
    </span>
  </button>
</template>

<script>
import { markFeatureUsed } from '@/api/featureUsage';
import { serverBasePath, serverURL, urlWithPaths } from '@/utils/paths';
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'OpenSoloButton',
  props: {
    dropdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      appHistoryDisplayedId: state => state.bundles.displayedId,
      adhocVariant: state => state.legacyParams.adhocVariant,
      serverSettings: state => state.server.settings,
      variant: state => state.parameterization.currentVariant,
      variantHistoryDisplayedId: state => state.contentView.renderingHistory.displayedId,
    }),
    url() {
      return this.app?.url;
    },
    hasVanityURL() {
      return this.app?.vanityUrl;
    },
    dataAutomation() {
      return this.dropdown ? 'menuitem-open-solo--dropdown' : 'menuitem-open-solo';
    },
    classes() {
      const classes = [`openSolo`];
      classes.push(this.dropdown ? 'narrow' : 'wide');
      return classes;
    },
    openSoloURL() {
      // NOTE: This used to come from `app.url`, but was changed to support
      // customer environments struggling with proxy configuration.
      let url = '';

      if (this.serverSettings?.useWindowLocation) {
        if (this.hasVanityURL) {
          try {
            const vanityURL = new URL(this.url);
            const vanityPath = vanityURL.pathname;

            if (vanityPath.startsWith(serverBasePath)) {
              url = serverURL(vanityPath.replace(serverBasePath, ''));
            } else {
              url = serverURL(vanityURL.pathname);
            }
            // eslint-disable-next-line no-unused-vars
          } catch (e) {
            // Unable to parse vanity URL, fallback to content URL
            url = '';
          }
        }

        // If we failed to map a vanity URL, just use a content URL
        if (!url) {
          url = serverURL(`content/${ this.app.guid }/`);
        }
      } else {
        url = this.url;
      }

      const paths = [];
      if (!isEmpty(this.variant)) {
        if (!isEmpty(this.adhocVariant)) {
          paths.push(`v${this.adhocVariant.key}`);
        } else if (!this.variant.isDefault) {
          paths.push(`v${this.variant.key}`);
        }

        if (this.showHistoricalVariant) {
          paths.push(`_rev${this.variantHistoryDisplayedId}/`);
        }
      } else if (this.appHistoryDisplayedId > 0
        && this.appHistoryDisplayedId !== String(this.app.bundleId)) {
        paths.push(`_rev${this.appHistoryDisplayedId}`);
      }

      return urlWithPaths(url, paths);
    },
    showHistoricalVariant() {
      return (
        isEmpty(this.adhocVariant) &&
        this.variantHistoryDisplayedId !== this.variant.renderingId
      );
    },
  },
  methods: {
    runAction() {
      markFeatureUsed('open_solo');
      window.open(this.openSoloURL, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.openSolo {
  background-image: url(Images/elements/actionSolo.svg);
  &.wide {
    display: inline-block;
  }

  &.narrow {
    display: none;
  }
}

@include for-medium-screens {
  .openSolo {
    &.wide {
      display: none;
    }

    &.narrow {
      display: block;
    }
  }
}
</style>
