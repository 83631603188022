<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { getCaptcha, login } from '@/api/authentication';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RSButton from '@/elements/RSButton.vue';
import RSInputPassword from '@/elements/RSInputPassword.vue';
import RSInputText from '@/elements/RSInputText.vue';
import RSModalForm from '@/elements/RSModalForm.vue';
import { computed, nextTick, onBeforeMount, onMounted, reactive, ref } from 'vue';

const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  authenticationName: {
    type: String,
    required: true,
  },
  challengeRequired: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['done']);
const passwordInput = ref(null);

const localState = reactive({
  processing: false,
  status: {
    show: false,
    message: null,
    type: null,
  },
  form: {
    password: '',
    challengeResponse: '',
  },
  challenge: {
    challengeId: null,
    audio: {
      mimeType: null,
      data: null,
    },
    image: {
      mimeType: null,
      data: null,
    },
  },
});

const imageData = computed(() => {
  const { mimeType, data } = localState.challenge.image;
  return `data:${mimeType};base64,${data}`;
});

const audioData = computed(() => {
  const { mimeType, data } = localState.challenge.audio;
  return `data:${mimeType};base64,${data}`;
});

onBeforeMount(async() => {
  if (!props.challengeRequired) {
    return Promise.resolve();
  }

  const timeoutId = setTimeout(() => { showStatusMessage('activity', 'Loading captcha...'); }, 300);

  return loadCaptchaData()
    .catch(err => {
      showStatusMessage('error', safeAPIErrorMessage(err));
    })
    .finally(() => {
      clearTimeout(timeoutId);
      if (localState.status.type === 'activity') {
        // only hide if status is no longer an activity message - i.e. error might be shown
        hideStatusMessage();
      }
    });
});

onMounted(() => {
  focusPassword();
});

const focusPassword = async() => {
  await nextTick();
  passwordInput.value?.focusElement();
};

const onSubmit = () => {
  localState.processing = true;
  showStatusMessage('activity', 'Confirming Password...');

  return login({
    username: props.username,
    password: localState.form.password,
    challengeId: localState.challenge.challengeId,
    challengeResponse: localState.form.challengeResponse,
  })
    .then(() => {
      emit('done');
    })
    .catch(err => {
      localState.form.password = '';
      showStatusMessage('error', safeAPIErrorMessage(err));
      if (props.challengeRequired) {
        localState.form.challengeResponse = '';
        return loadCaptchaData();
      }
    })
    .finally(() => {
      localState.processing = false;
      focusPassword();
    });
};

const loadCaptchaData = async() =>
  getCaptcha()
    .then(({ challengeId, challengeData = [] }) => {
      localState.challenge.challengeId = challengeId;
      challengeData.forEach(({ mimeType, payload }) => {
        if (mimeType.startsWith('image')) {
          localState.challenge.image.mimeType = mimeType;
          localState.challenge.image.data = payload;
        }

        if (mimeType.startsWith('audio')) {
          localState.challenge.audio.mimeType = mimeType;
          localState.challenge.audio.data = payload;
        }
      });
    })
    .catch(err => {
      showStatusMessage('error', safeAPIErrorMessage(err));
    });

const showStatusMessage = (type, message) => {
  localState.status.message = message;
  localState.status.type = type;
  localState.status.show = true;
};

const hideStatusMessage = () => {
  localState.status.show = false;
  localState.status.message = null;
  localState.status.type = null;
};
</script>

<template>
  <RSModalForm
    :active="true"
    :closeable="false"
    :subject="`For security purposes, please re-enter the password associated with your ${authenticationName} credentials.`"
    data-automation="cpd-dialog"
    @submit="onSubmit"
  >
    <template #content>
      <fieldset :disabled="localState.processing">
        <div
          v-if="localState.status.show"
          class="rs-field"
        >
          <EmbeddedStatusMessage
            :message="localState.status.message"
            :show-close="false"
            :type="localState.status.type"
            data-automation="cpd-status-message"
            @close="hideStatusMessage"
          />
        </div>
        <RSInputPassword
          ref="passwordInput"
          v-model="localState.form.password"
          label="Password"
          name="cpd-password"
          data-automation="cpd-password"
          autocomplete="off"
        />
        <RSInputText
          v-if="challengeRequired"
          v-model="localState.form.challengeResponse"
          label="Captcha Code"
          name="cpd-challenge-response"
          data-automation="cpd-challenge-response"
          autocomplete="off"
        />
        <div
          v-if="localState.challenge.image.mimeType"
          class="rs-field center"
        >
          <img
            alt="Captcha Image"
            :src="imageData"
            class="rsc-full-width"
          >
        </div>
        <div
          v-if="localState.challenge.audio.mimeType"
          class="rs-field center"
        >
          <!-- eslint-disable vuejs-accessibility/media-has-caption -->
          <audio
            controls
            class="rsc-full-width"
          >
            Captcha audio is unsupported on this browser.
            <source
              :src="audioData"
              :type="localState.challenge.audio.mimeType"
            >
          </audio>
          <!-- eslint-enable vuejs-accessibility/media-has-caption -->
        </div>
      </fieldset>
    </template>
    <template #controls>
      <RSButton
        id="cpd-submit"
        label="Confirm Password"
        :disabled="localState.processing"
        data-automation="cpd-submit"
      />
    </template>
  </RSModalForm>
</template>

<style lang="scss" scoped>
.rsc-full-width {
  width: 100%;
}
</style>
