<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div
    v-if="appSettings"
    class="params-panel contentPanel"
    :class="{ closed: !showPanel }"
  >
    <div class="settingsPane">
      <RSInformationToggle
        class="formSection params-panel__title"
      >
        <template #title>
          <div
            data-automation="params-title"
          >
            Parameters for {{ variantName }}
          </div>
        </template>
        <template #help>
          <div
            v-if="canEdit"
            class="params-panel__help"
          >
            <p>
              This panel lets you manage the variants (sets of parameter
              values) for this parameterized document.
            </p>
            <p>
              You cannot save parameter value overrides to the default
              variant; it uses the parameter values declared in your document.
            </p>
            <p>
              You can change and save parameter values without running the
              document. The new parameter values are used when the document is
              next rendered.
            </p>
            <p>
              If you do run the document, that rendering is saved along with
              any changes to parameter values.
            </p>
          </div>
          <div v-if="canViewerCustomize && !canEdit">
            <p>
              This panel lets you run this parameterized document and
              optionally modify the parameter values used while rendering.
            </p>
          </div>
          <div v-if="canViewerOnDemand && !canViewerCustomize && !canEdit">
            <p>
              This panel lets you run this parameterized document with a
              specific set of parameter values.
            </p>
          </div>
          <div v-if="!canViewerOnDemand && !canViewerCustomize && !canEdit">
            <p>
              This panel lets you see the sets of parameter values used when
              running this parameterized document.
            </p>
          </div>
        </template>
        <template #rightSideControls>
          <button
            v-if="showPanel"
            aria-label="Close"
            class="params-panel__close"
            data-automation="parameters-panel-close"
            @click="close"
          />
        </template>
      </RSInformationToggle>
      
      <div v-if="showPanel">
        <div
          v-if="showProcessing"
          class="contentMessageContainer"
          data-automation="params-processing"
        >
          <div class="genMessage">
            <div class="genMessageSpinnerContainer">
              <Spinner />
            </div>
            Processing...
          </div>
        </div>

        <div
          v-show="!showProcessing"
          data-automation="legacy-params-pane"
        >
          <div v-if="hasAdhocVariant">
            <ParametersEditor
              v-if="canEdit || canViewerCustomize"
              :url="configurationURL"
            />
          </div>

          <div
            v-if="showNotEditable"
            data-automation="params-not-editable"
          >
            <p class="params-panel__description">
              You do not have privileges to customize parameters for this report.
              The configured parameter values are displayed below.
            </p>
            <pre
              class="paramOverrides"
              data-automation="params-not-editable__pre"
            >{{ overrideValues }}</pre>
          </div>

          <div class="formSection">
            <p class="params-panel__render-info">
              <span v-if="currentVariant.renderTime">
                Output last rendered on {{ lastRenderedTime }}.
              </span>
              <span v-else>
                Output using the current parameters not generated.
              </span>
            </p>
          </div>

          <ParametersActions
            v-if="canEdit || canViewerCustomize || canViewerOnDemand"
            @delete-variant="isDeletingVariant = true"
          />
        </div>
      </div>
    </div>

    <button
      v-if="!showPanel"
      aria-label="Open"
      class="params-panel__toggler"
      data-automation="parameters-panel-toggle"
      @click="open"
    >
      Input
    </button>

    <RenameVariantModal v-if="renamingVariant" />
    <SaveAsModal v-if="savingVariant" />

    <!-- Confirm unsaved changes modal -->
    <ConfirmModal
      v-if="showUnsavedChangesModal"
      subject="Confirm Action"
      details="You have unsaved changes that will be lost if you close the input panel. Are you sure you wish to continue?"
      @confirm="confirmUnsaved"
      @cancel="cancelUnsaved"
    />
  </div>
</template>

<script>
import { getApplicationsSettings } from '@/api/serverSettings';
import ConfirmModal from '@/components/ConfirmModal';
import Spinner from '@/components/Spinner';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  PARAMETERS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';
import {
  LEGACY_PARAMS_CLEAR,
  LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES,
  LEGACY_PARAMS_LOAD,
  LEGACY_PARAMS_SET_UNSAVED_MODAL,
  LEGACY_PARAMS_START,
} from '@/store/modules/legacyParams';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { serverURL } from '@/utils/paths';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import ParametersActions from './ParametersActions';
import ParametersEditor from './ParametersEditor';
import RenameVariantModal from './RenameVariantModal';
import SaveAsModal from './SaveAsModal';

export default {
  name: 'LegacyParametersPanel',
  components: {
    Spinner,
    RSInformationToggle,
    ParametersEditor,
    ParametersActions,
    RenameVariantModal,
    SaveAsModal,
    ConfirmModal,
  },
  data: () => ({
    appSettings: null,
    isDeletingVariant: false,
    overrides: null,
  }),
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      app: state => state.contentView.app,
      showPanel: state => state.contentView.showParametersPanel,
      
      currentVariant: state => state.parameterization.currentVariant,
      adhocVariant: state => state.legacyParams.adhocVariant,
      editingSession: state => state.legacyParams.editingSession,
      
      isBusy: state => state.legacyParams.isBusy,
      paramsForm: state => state.legacyParams.form,
      paramsOverrides: state => state.legacyParams.overrides,
      renamingVariant: state => state.legacyParams.renamingVariant,
      savingVariant: state => state.legacyParams.savingVariant,
      showUnsavedChangesModal: state => state.legacyParams.unsavedChanges.showModal,
    }),
    isReady() {
      return this.paramsForm.loaded && !this.isBusy;
    },
    hasAdhocVariant() {
      return (!isEmpty(this.adhocVariant));
    },
    configurationURL() {
      const variantId = this.adhocVariant.id;
      const sessionId = this.editingSession;
      return serverURL(`/__configure__/${variantId}/${sessionId}`);
    },
    canEdit() {
      return this.currentUser.isAppEditor(this.app);
    },
    canViewerOnDemand() {
      return this.currentUser.canViewApp(this.app) && this.appSettings.viewerOnDemandReports;
    },
    canViewerCustomize() {
      return this.currentUser.canViewApp(this.app) && this.appSettings.viewerCustomizedReports;
    },
    variantName() {
      return this.currentVariant?.name || '';
    },
    lastRenderedTime() {
      return dayjs(this.currentVariant.renderTime).format('MMM D, YYYY h:mma ([GMT]Z)');
    },
    showProcessing() {
      return !this.isReady && !this.showNotEditable;
    },
    showNotEditable() {
      if (this.canEdit) {
        return false;
      }
      return !this.canViewerCustomize;
    },
    overrideValues() {
      const noCustomParams = 'No custom parameters';
      
      if (isEmpty(this.paramsOverrides) || !this.paramsOverrides.values) {
        return noCustomParams;
      }
      
      try {
        return JSON.stringify(JSON.parse(this.paramsOverrides.values), null, 2);
      } catch {
        return noCustomParams;
      }
    },
  },
  watch: {
    currentVariant(newVariant) {
      this.clearParams();
      if (this.showPanel && !this.isDeletingVariant) {
        if (this.canEdit || this.canViewerOnDemand || this.canViewerCustomize) {
          this.startParams(newVariant.id);
        } else {
          this.loadParams(newVariant.id);
        }
      }
      this.isDeletingVariant = false;
    },
  },
  created() {
    getApplicationsSettings()
      .then(settings => {
        this.appSettings = settings;
      })
      .catch(this.setErrorMessageFromAPI);
  },
  methods: {
    ...mapActions({
      loadParams: LEGACY_PARAMS_LOAD,
      startParams: LEGACY_PARAMS_START,
      togglePanels: TOGGLE_PANELS,
      ignoreChanges: LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES,
    }),
    ...mapMutations({
      clearParams: LEGACY_PARAMS_CLEAR,
      setUnsavedModal: LEGACY_PARAMS_SET_UNSAVED_MODAL,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    open() {
      this.togglePanels({ panel: PARAMETERS_PANEL, action: OPEN_PANEL });
      if (this.canEdit || this.canViewerOnDemand || this.canViewerCustomize) {
        this.startParams(this.currentVariant.id);
      } else {
        this.loadParams(this.currentVariant.id);
      }
    },
    close() {
      this.togglePanels({ panel: PARAMETERS_PANEL, action: CLOSE_PANEL });

      // If params form isn't dirty, we can clear up state right away
      // else, the toggle panels action will call for the confirm modal.
      if (!this.paramsForm.dirty) {
        this.clearParams();
      }
    },
    async confirmUnsaved() {
      await this.ignoreChanges();
    },
    cancelUnsaved() {
      this.setUnsavedModal({ show: false });
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.params-panel {
  left: 0;
  width: $params-panel-width;
  background-color: $color-white;
  border-right: 1px solid $color-light-grey-4;

  z-index: 20;

  @include transition-property(left);
  @include normal-transition-duration();

  &__toggler {
    position:absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: $params-panel-closed-width;
    background-color: $color-white;
    background-image: url(Images/params-icon.svg);
    background-position: center 16px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    font-size: 0.85rem;
    color: $color-primary;
    &:hover {
      background-color: $color-primary;
      background-image: url(Images/params-icon-white.svg);
      color: $color-white;
    }
  }

  &__close {
    height: 30px;
    width: 30px;
    padding: 0;

    background: url(Images/close.svg) no-repeat center center;

    border-radius: 15px;
    @include control-visible-focus(50%);

    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }
  }

  &.closed {
    left: $params-panel-closed-left;
    overflow: visible;
  }

  &__render-info {
    line-height: normal;
  }

  &__description {
    margin-bottom: 1rem;
  }

  .paramOverrides {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
</style>
