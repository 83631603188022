<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Print"
    title="Print"
    class="action print content-actions__print-button"
    :data-automation="dataAutomation"
    @click="runAction"
  >
    <span
      v-if="dropdown"
      class="actionTitle"
    >
      Print
    </span>
  </button>
</template>

<script>
export default {
  name: 'PrintButton',
  props: {
    dropdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataAutomation() {
      return this.dropdown ? 'menuitem-print--dropdown' : 'menuitem-print';
    },
  },
  methods: {
    runAction() {
      // NOTE: copy-pasta'd straight out of angular, but we can probably do better
      document.getElementById('contentIFrame').contentWindow.print();
    }
  }
};
</script>

<style lang="scss" scoped>
.print {
  background-image: url(Images/elements/actionPrint.svg);
}
</style>
