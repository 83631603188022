<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script>
export const BUNDLE_HISTORY = 'BUNDLE_HISTORY';
export const RENDERING_HISTORY = 'RENDERING_HISTORY';
</script>

<script setup>
import Tooltip from '@/components/Tooltip.vue';
import { localOffset } from '@/utils/timezone';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import _sortBy from 'lodash/sortBy';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  displayedId: {
    type: [Number, String],
    required: true,
  },
  sortBy: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['displayItem', 'toggleHistory']);

const dateFieldMap = {
  [BUNDLE_HISTORY]: 'createdTime',
  [RENDERING_HISTORY]: 'renderTime',
};

const innerContainer = ref(null);
const itemRefs = ref([]);

const localState = reactive({
  shouldEnableLeftNavigation: false,
  shouldEnableRightNavigation: false,
  timeInfo: `All times are displayed in local time ${localOffset()}`,
});

const historyList = computed(() => {
  const formattedHistory = _sortBy(props.items, props.sortBy).map(item => ({
    ...item,
    [dateField.value]: dayjs(item[dateField.value]).format('M/D/YY h:mma'),
  }));

  return formattedHistory;
});
const dateField = computed(() => dateFieldMap[props.type]);

const typeProse = computed(() => {
  if (props.type === RENDERING_HISTORY) {
    return 'rendering';
  } else if (props.type === BUNDLE_HISTORY) {
    return 'bundle';
  }
  return props.type;
});

watch(
  () => itemRefs.value,
  () => {
    nextTick().then(() => {
      // Navigate to the selected item when the list is ready.
      const selectedElement = getSelectedElement();
      selectedElement?.scrollIntoView(false);
    });
  },
  { deep: true }
);

onMounted(() => {
  registerScrollEvent();
  registerResizeEvent();
  nextTick(scrollAfterRender);
});
onBeforeUnmount(() => {
  innerContainer.value.removeEventListener('scroll', debouncedCheckScroll);
  window.removeEventListener('resize', debouncedCheckScroll);
});

const debouncedCheckScroll = () =>
  debounce(() => {
    const maxScrollLeft =
      innerContainer.value.scrollWidth - innerContainer.value.clientWidth;
    const SCROLL_OFFSET = 5;
    localState.shouldEnableLeftNavigation =
      innerContainer.value.scrollLeft > SCROLL_OFFSET;
    localState.shouldEnableRightNavigation =
      innerContainer.value.scrollLeft < maxScrollLeft - SCROLL_OFFSET;
  }, 250);

const registerScrollEvent = () => {
  innerContainer.value.addEventListener('scroll', debouncedCheckScroll);
};

const registerResizeEvent = () => {
  window.addEventListener('resize', debouncedCheckScroll);
};

const onDisplayItem = (item, index) => {
  emit('displayItem', item);
  if ('scrollIntoView' in itemRefs.value[index]) {
    itemRefs.value[index].scrollIntoView({ behavior: 'smooth' });
  }
};

/**
 * Get all the DOM Element objects for all the clickable history timestamps
 * in the header.
 * Note that this method returns a JavaScript array of Elements, not a
 * NodeList, so that modern array methods like .filter() will be available.
 * @returns {Array.<Element>}
 */
const getAllHistoryItems = () =>
  itemRefs.value ? Array.from(itemRefs.value) : [];

/**
 * Get all the clickable history timestamp Elements that are currently
 * visible.
 * @returns {Array.<Element>}
 */
const getVisibleHistoryItems = () => {
  const containerRect = innerContainer.value.getBoundingClientRect();
  return getAllHistoryItems().filter(element => {
    const { left, right } = element.getBoundingClientRect();
    return left >= containerRect.left && right <= containerRect.right;
  });
};

/**
 * Find the Element that is a half-page to the left of the current view
 * and scroll it into view.
 */
const doScrollLeft = () => {
  const allHistoryItems = getAllHistoryItems();
  const visibleHistoryItems = getVisibleHistoryItems();

  if (allHistoryItems[0] === visibleHistoryItems[0]) {
    return;
  }

  const halfPageNumberOfItems = Math.ceil(visibleHistoryItems.length / 2);
  const firstVisibleItem = visibleHistoryItems[0];
  const firstVisibleItemIndex = allHistoryItems.findIndex(
    element => element === firstVisibleItem
  );
  const prevPageMiddleItemIndex = Math.max(
    0,
    firstVisibleItemIndex - halfPageNumberOfItems
  );
  const previousPageItem = allHistoryItems[prevPageMiddleItemIndex];
  previousPageItem.scrollIntoView({ behavior: 'smooth' });
};

/**
 * Find the Element that is a half-page to the right of the current view
 * and scroll it into view.
 */
const doScrollRight = () => {
  const allHistoryItems = getAllHistoryItems();
  const visibleHistoryItems = getVisibleHistoryItems();
  const lastItemIndex = allHistoryItems.length - 1;
  const lastHistoryItem = allHistoryItems[lastItemIndex];
  const lastVisibleHistoryItem =
    visibleHistoryItems[visibleHistoryItems.length - 1];

  if (lastHistoryItem === lastVisibleHistoryItem) {
    return;
  }

  const halfPageNumberOfItems = Math.ceil(visibleHistoryItems.length / 2);
  const lastVisibleItemIndex = allHistoryItems.findIndex(
    element => element === lastVisibleHistoryItem
  );
  const nextPageMiddleItemIndex = Math.min(
    lastItemIndex,
    lastVisibleItemIndex + halfPageNumberOfItems
  );
  const nextPageItem = allHistoryItems[nextPageMiddleItemIndex];
  nextPageItem.scrollIntoView({ behavior: 'smooth' });
};

const getActiveElement = () =>
  itemRefs.value
    ? itemRefs.value.find(el => el.className.includes('active'))
    : null;

const getSelectedElement = () =>
  itemRefs.value
    ? itemRefs.value.find(el => el.className.includes('selected'))
    : null;

/**
 * Find the Element that is active on load and scroll it into view to make
 * sure it is visible immediately.
 * If it cannot find the element, then nothing happens.
 * If it does find the element, then it scrolls to it.
 */
const scrollAfterRender = () => {
  const activeElement = getActiveElement();
  activeElement?.scrollIntoView();
};
</script>

<template>
  <div
    :id="`${typeProse}-history-container`"
    class="historyBarContainer"
    :data-automation="`${typeProse}-history`"
  >
    <div class="actionBar inline">
      <button
        class="action arrowLeft"
        :disabled="!localState.shouldEnableLeftNavigation"
        :aria-label="`Older ${capitalize(typeProse)}s`"
        @click="doScrollLeft"
      />
      <button
        class="action arrowRight first"
        :disabled="!localState.shouldEnableRightNavigation"
        :aria-label="`Newer ${capitalize(typeProse)}s`"
        @click="doScrollRight"
      />
    </div>
    <div
      ref="innerContainer"
      class="listOuterContainer"
    >
      <div class="listContainer">
        <div class="listbox">
          <button
            v-for="(item, index) in historyList"
            ref="itemRefs"
            :key="item.id"
            :data-automation="`history-item-${index + 1}`"
            class="item"
            :class="{
              selected: item.id === displayedId,
              active: item.active
            }"
            @click="onDisplayItem(item, index)"
          >
            {{ item[dateField] }}
          </button>
        </div>
      </div>
    </div>
    <div class="info-toggle-container">
      <Tooltip
        data-automation="time-info-tooltip"
        top="1.5em"
        right="1em"
      >
        <i
          class="infoToggle"
          data-automation="history-info-toggle"
          role="button"
          tabindex="0"
          :aria-label="localState.timeInfo"
        />
        <template #tip>
          {{ localState.timeInfo }}
        </template>
      </Tooltip>
    </div>

    <button
      aria-label="Close History"
      class="barClose"
      data-automation="history-close"
      tabindex="0"
      @click="$emit('toggleHistory')"
    />
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.historyBarContainer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px 2.5% 10px 2.5%;

  @include flex-space-between();
  align-items: center;
}

.listOuterContainer {
  width: 100%;
  border-left: 1px solid $color-medium-grey;
  margin: 0 5px 0 10px;
  line-height: 20px;
  min-height: 2rem;

  position: relative;
  overflow: auto;

  .listContainer {
    white-space: nowrap;

    @include transition-property(right);
    @include slow-transition-duration();

    .listbox {
      margin-bottom: 2px;
      background-color: $color-white;
      padding-top: 2px;
      border: none;
      text-align: right;

      .item {
        border-radius: 3px;
        color: #666;
        height: auto;
        line-height: inherit;
        padding: 5px 10px;
        cursor: pointer;
        display: inline-block;
        width: auto;
        margin: 0 5px;
        @include control-visible-focus;

        &:hover {
          background-color: $color-light-grey;
          text-decoration: none;
        }

        &.selected {
          background-color: $color-primary;
          color: $color-primary-inverse;
        }

        &.active {
          font-weight: bold;
          border: 1px solid $color-primary;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

div.info-toggle-container {
  display: flex;
  position: relative;
  align-items: center;
  border-right: 1px solid #c8c8c8;
  padding-right: 5px;
  margin-right: 10px;
  align-self: stretch;

  .time-info {
    @include normal-drop-shadow;
    display: flex;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: max-content;
    background-color: $color-info-background;
    border: 1px solid $color-info-border;
    color: $color-info;
    padding: 5px;
    z-index: 1;
    animation: delayed-fade-out 5s;
  }
}

.infoToggle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 15px;

  background-repeat: no-repeat;
  background-size: 25px 50px;
  background-position: center 0px;
  background-image: url('Images/elements/actionToggleInfo.svg');

  &:hover {
    background-color: $color-light-grey-2;
  }
}

@keyframes delayed-fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.barClose {
  cursor: pointer;
  height: 30px;
  width: 30px;
  padding: 0;

  background: url(Images/close.svg) no-repeat center center;
  flex-shrink: 0;

  border-radius: 15px;
  @include control-visible-focus(50%);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.arrowLeft {
  background-image: url(Images/elements/arrowLeft.svg);
}

.arrowRight {
  background-image: url(Images/elements/arrowRight.svg);
}
</style>
