<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import UserRoles from '@/api/dto/userRole';
import { getAPIKeys } from '@/api/users';
import BaseButton from '@/components/BaseButton';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { docsPath } from '@/utils/paths';
import CreateKey from '@/views/users/apikeys/CreateKey';
import DeleteKey from '@/views/users/apikeys/DeleteKey';
import KeysTable from '@/views/users/apikeys/KeysTable';
import NewKeyConfirmation from '@/views/users/apikeys/NewKeyConfirmation';
import { computed, onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  guid: {
    type: String,
    required: true,
  },
});

const store = useStore();

const localState = reactive({
  loaded: false,
  newKey: null,
  deleteKeyTarget: null,
  showDeleteModal: false,
  showCreateModal: false,
  showNewKeyConfirmation: false,
  apiKeys: [],
});

const currentUser = computed(() => store.state.currentUser.user);
const systemDisplayName = computed(() => store.state.server.settings.systemDisplayName);
const apiKeysActive = computed(() => store.state.server.settings.authentication.apiKeyAuth);
const hasKeys = computed(() => localState.apiKeys.length > 0);
const roleString = computed(() => UserRoles.stringOf(currentUser.value.userRole));

onBeforeMount(() => {
  pullUserKeys();
});

const pullUserKeys = async() => {
  if (currentUser.value.guid === props.guid) {
    try {
      localState.apiKeys = (await getAPIKeys(currentUser.value.guid)).reverse();
      localState.loaded = true;
    } catch (e) {
      store.commit(SET_ERROR_MESSAGE_FROM_API, e);
    }
  }
};
const onDeleteRequest = (key) => {
  localState.deleteKeyTarget = key;
  toggleDeleteKeyModal();
};

const toggleDeleteKeyModal = () => {
  localState.showDeleteModal = !localState.showDeleteModal;
};

const toggleCreateKeyModal = () => {
  localState.showCreateModal = !localState.showCreateModal;
};

const onKeyDeleted = () => {
  pullUserKeys();
  toggleDeleteKeyModal();

  store.dispatch(SHOW_INFO_MESSAGE, {
    message: 'API Key Deleted.',
  });
};

const onKeyCreated = (newKey) => {
  pullUserKeys();
  toggleCreateKeyModal();
  localState.newKey = newKey;
  localState.showNewKeyConfirmation = true;
};

const clearNewKeyConfirmation = () => {
  localState.showNewKeyConfirmation = false;
  localState.newKey = null;
};
</script>

<template>
  <div
    v-if="localState.loaded"
    class="majorMinorColumnsContainer"
  >
    <div class="majorColumn">
      <div class="sectionTitle flex">
        <h1
          class="view-title"
          data-automation="api-keys-title"
        >
          API Keys
        </h1>
        <div class="actionBar inline showTitles">
          <BaseButton
            title="New API Key"
            label="New API Key"
            aria-label="Add new API key"
            class="action new"
            data-automation="add-new-api-key"
            :disabled="!apiKeysActive"
            @clicked.prevent="toggleCreateKeyModal"
          />
        </div>
      </div>

      <p
        v-if="hasKeys && !apiKeysActive"
        class="disabled-notice"
      >
        API key creation has been disabled. You can continue to manage existing keys below.
      </p>

      <KeysTable
        v-if="hasKeys"
        :keys="localState.apiKeys"
        :current-user="currentUser"
        @delete-intention="onDeleteRequest"
      />
      <p
        v-else-if="!hasKeys && apiKeysActive"
        class="no-keys-msg"
      >
        You don't have any API keys. To create one, click the <span class="emphasize">New API Key</span> button above.
      </p>
      <p
        v-else
      >
        API keys are not enabled in your {{ systemDisplayName }} server.
        Please contact your system administrator for assistance.
      </p>
    </div>
    <div class="minorColumn">
      <div
        data-automation="api-keys__about__container"
        class="section"
      >
        <h2 class="sectionTitle view-subtitle">
          About API Keys
        </h2>
        <div class="sectionBlurb">
          <p>
            <span class="emphasize">
              Treat your API key like you treat your password.
              Anyone with your API key can impersonate you.
            </span>
          </p>
          <p>
            API keys enable you to send requests to {{ systemDisplayName }} as
            though you were a logged-in user. For example, you could use these
            keys to automate requests to hosted content that require
            authentication. Keys will have the same rights and permissions as
            you, unless you assign a more restrictive role.
          </p>
          <p>
            Note that a key cannot have a more permissive role than its
            associated user.
          </p>
          <p>
            See the
            <a
              :href="docsPath('/user/api-keys')"
              target="_blank"
            >
              API Keys documentation
            </a>
            for more details.
          </p>
        </div>
      </div>
    </div>
  </div>
  <DeleteKey
    v-if="localState.showDeleteModal"
    :guid="currentUser.guid"
    :api-key="localState.deleteKeyTarget"
    @deleted="onKeyDeleted"
    @close="toggleDeleteKeyModal"
  />
  <CreateKey
    v-if="localState.showCreateModal"
    :guid="currentUser.guid"
    :user-role="roleString"
    @created="onKeyCreated"
    @close="toggleCreateKeyModal"
  />
  <NewKeyConfirmation
    v-if="localState.showNewKeyConfirmation"
    :api-key="localState.newKey"
    @close="clearNewKeyConfirmation"
  />
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';
@import 'Styles/shared/_variables';
.majorMinorColumnsContainer {
    @include flex-space-between();
    align-items: flex-start;

    .majorColumn {
      width: $major-column-width;
      overflow-x: auto;
      min-height: 500px;
    }

    .minorColumn {
      width: $minor-column-width;
      min-width: $min-minor-column-width;
      padding-left: $major-minor-padding;
    }
}

@include for-large-screens() {
  .majorMinorColumnsContainer {
    @include flex-allow-wrap();

    .majorColumn, .minorColumn {
      min-height: 0;
      width: 100%;
    }

    .majorColumn {
      margin-bottom: 60px;
    }

    .minorColumn {
      padding-left: 0px;
    }
  }
}
.view-title {
  font-size: 1.5rem;
}
.view-subtitle {
  font-size: 1.25rem;
}
.no-keys-msg {
  padding: 2rem;
  text-align: center;
}
.sectionBlurb p {
  margin-bottom: 1rem;
}
.disabled-notice {
  margin-bottom: 1rem;
}

.new {
  background-image: url(Images/elements/actionNew.svg);
}
</style>
