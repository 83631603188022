<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import isEmpty from 'lodash/isEmpty';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import EmailDialog from './EmailDialog';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const isBusy = computed(() => store.state.parameterization.isBusy);
const isInitialized = computed(
  () => !isEmpty(store.state.parameterization.currentVariant)
);
const emailDialogActive = ref(false);
</script>

<template>
  <span>
    <button
      type="button"
      aria-label="Email Report"
      title="Email Report"
      class="action email content-actions__email-report-button"
      data-automation="menuitem-email-report"
      :disabled="isBusy || !isInitialized || disabled"
      @click="emailDialogActive = true"
    />
    <EmailDialog
      v-if="emailDialogActive"
      @hide="emailDialogActive = false"
    />
  </span>
</template>

<style lang="scss" scoped>
.email {
  background-image: url(Images/elements/actionEmail.svg);
}
</style>
