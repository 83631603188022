<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import RSButton from '@/elements/RSButton.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  activeAuth: { type: Object, default: () => {} },
  saved: { type: Boolean, default: false }
});

const emit = defineEmits(['openModal', 'integrationChange']);
const store = useStore();

const removeActiveIntegration = () => emit('integrationChange', []);
const openIntegrationModal = () => emit('openModal');

const app = store.state.contentView.app;
const canEdit = computed(() => store.state.currentUser.user.canEditAppSettings(app));
</script>

<template>
  <div
    class="active-integration"
    data-automation="active-integration"
    :integration-guid="props.activeAuth.value"
  > 
    <div
      v-if="canEdit"
      class="active-integration-controls"
      data-automation="active-integration-controls"
    >
      <RSButton
        class="change-active-integration"
        data-automation="change-active-integration"
        :icon-only="true"
        icon="images/elements/actionEdit.svg"
        type="link"
        label="Change Active Integration"
        size="normal"
        @click="openIntegrationModal"
      />
      <RSButton
        class="remove-active-integration"
        data-automation="remove-active-integration"
        :icon-only="true"
        icon="images/elements/actionDelete.svg"
        type="link"
        label="Remove Active Integration"
        size="normal"
        @click="removeActiveIntegration"
      />
    </div>
    <div
      class="active-integration-content"
      data-automation="active-integration-content"
    >
      <div
        class="active-integration-title"
      >
        <img
          :src="`images/oauthintegrations/${props.activeAuth.template}.png`"
          :alt="`${props.activeAuth.template} integration`"
          class="active-integration-logo"
        >
        <p
          class="active-integration-name"
          data-automation="active-integration-name"
        >
          {{ props.activeAuth.label }}
        </p>
      </div>
      <p
        class="active-integration-description"
        data-automation="active-integration-description"
      >
        {{ props.activeAuth.sub }}
      </p>
    </div>
    <a
      v-if="props.activeAuth.authType === 'Viewer' && saved"
      :href="props.activeAuth.href"
      data-automation="active-integration-login"
      class="active-integration-login"
    >
      {{ props.activeAuth.linkName }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.active-integration {
  background: $color-white;
  border: 1px solid $color-medium-grey;
  display: flex;
  flex-flow: column;
  position: relative;
}

.active-integration-controls {
  position: absolute;
  right: 0;
  .change-active-integration, .remove-active-integration {
    padding: 0.1rem;
  }
}

.active-integration-content {
  margin: 2rem 1.5rem;
}

.active-integration-title {
  display: flex;
  margin-bottom: 0.5rem;
}

.active-integration-logo {
  height: 14px;
  position: relative;
  margin-right: 0.4rem;
}

.active-integration-name {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
}

.active-integration-description {
  color: $color-dark-grey-2;
  line-height: 0.875rem;
  margin-left: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 225px;
}

.active-integration-login {
  background-color: $color-dark-blue;
  color: $color-white;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0.375rem;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
</style>
