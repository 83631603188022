<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <RSInformationToggle v-if="hasHelp">
      <template
        v-if="showLabel"
        #title
      >
        <label
          :for="name"
          :class="{small}"
          class="rs-field__help-label"
        >
          {{ label }}
        </label>
      </template>
      <template #help>
        <span v-if="$slots.help">
          <slot name="help" />
        </span>
        <span v-else>{{ help }}</span>
      </template>
    </RSInformationToggle>
    <div
      v-if="!hasHelp && showLabel"
      class="rs-field__help"
    >
      <label
        :for="name"
        :class="{small}"
        class="rs-field__help-label"
      >
        {{ label }}
      </label>
    </div>
    <div class="rs-field__control">
      <input
        v-bind="$attrs"
        :id="name"
        :aria-label="label"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo, small }"
        :name="name"
        class="rs-input"
        type="number"
        :value="modelValue"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        @input="handleValue"
      >
    </div>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

import RSInformationToggle from './RSInformationToggle.vue';

export default {
  name: 'RSInputNumber',
  components: { RSInformationToggle },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    help: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage,
    },
    small: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    hasMessage() {
      return Boolean(this.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
  },
  methods: {
    handleValue(ev) {
      this.$emit('change', ev.target.value);
      this.$emit('update:modelValue', ev.target.value);
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

input {
  box-sizing: border-box;
  padding: 5px 10px;
}

input, textarea {
  border: 1px solid $color-medium-grey;
  &:disabled, &:read-only {
    @include control-disabled-input;
  }
}

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__label {
    display: block;
    @include label;

    &.small {
      @include label(true);
    }
  }

  &__control {
    & .rs-input {
      flex: 1;
    }

    &--icon {
      & .rs-icon {
        position: absolute;
        top: 4px;
        left: 4px;
        bottom: 0;
      }
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-label {
      @include label;

      &.small {
        @include label(true);
      }
    }
  }
}

.rs-input {
  max-width: 100%;
  font-size: $rs-font-size-normal;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: $color-secondary-inverse;
  height: 2rem;

  &:focus {
    @include control-focus;
  }

  &.small {
    font-size: $rs-font-size-small;
    padding: 0.25rem 0.4rem;
  }

  @include message-state;
}
</style>
