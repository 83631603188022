<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading content usage..."
      :show-close="false"
      type="activity"
      data-automation="usage-loading"
    />
    <div
      v-if="!loading"
      class="formSection"
      data-automation="usage-graph-panel"
    >
      <RSInformationToggle>
        <template #title>
          Past 30 days usage
        </template>
        <template #help>
          <div data-automation="usage-graph-info">
            <p>
              Usage data for the last 30 days is summarized across all versions and variations of this content.
              <span v-if="apiType === 'shiny'">
                Total number of visits plus the total time users spent interacting with the content along with
                a plot of the daily visit count are displayed.
              </span>
              <span v-else>
                Total number of visits along with a plot of the daily visit count are displayed.
              </span>
            </p>
            <p v-if="partial">
              Due to high request volume for this content item, partial data is displayed.
            </p>
            <p>
              Use the Instrumentation Usage APIs to perform your own analysis. Start with the recipes in the
              <a
                target="_blank"
                :href="usageDocs()"
              >
                API Cookbook
              </a>.
            </p>
          </div>
        </template>
      </RSInformationToggle>
      <div class="flexCenter">
        <div class="usageDetails">
          <div
            title="Views"
            class="usageDetail count"
            data-automation="usage-detail-count"
            aria-describedby="usageDetailDesc"
          >
            <span
              id="usageDetailDesc"
              style="display:none"
            >
              Views
            </span>
            <span
              data-automation="usage-detail-count-value"
            >{{ accessCount }}</span>
          </div>
          <div
            v-show="totalSessionTime"
            title="Total session duration"
            class="usageDetail duration"
            data-automation="usage-detail-session"
            aria-describedby="sessionDetailDesc"
          >
            <span
              id="sessionDetailDesc"
              style="display:none"
            >
              Total Session Duration
            </span>
            <span>{{ sessionTimeString }}</span>
          </div>
        </div>
        <div class="usageGraphContainer">
          <canvas
            ref="canvas"
            data-automation="usage-graph-canvas"
            aria-label="Usage Graph"
            role="img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import { RESET_CHART_DATA } from '@/store/modules/infoSettings';
import { docsPath } from '@/utils/paths';
import { limitedPrecisionDuration } from '@/utils/timeFormatting';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { markRaw } from 'vue';
import { mapMutations, mapState } from 'vuex';

function chartOptions(contentCreationDateLabel, maxXValue) {
  return {
    annotation: {
      // Defines when the annotations are drawn.
      // This allows positioning of the annotation relative to the other
      // elements of the graph.
      //
      // Should be one of: afterDraw, afterDatasetsDraw, beforeDatasetsDraw
      // See http://www.chartjs.org/docs/#advanced-usage-creating-plugins
      drawTime: 'afterDatasetsDraw',

      // Draw a vertical line at the point the content was initially deployed
      // IF and ONLY IF that date exists within this chart as an x-axis.
      annotations: [
        {
          // drawTime: 'afterDraw', // overrides annotation.drawTime if set
          // id: 'a-line-1', // optional
          type: 'line',
          // mode: 'horizontal',
          mode: 'vertical',
          // scaleID: 'x-axis-0',
          value: contentCreationDateLabel, // label to draw line at... ok if doesn't exist
          scaleID: 'x-axis-0',
          borderColor: '#ececec',
          borderWidth: 2,
        },
      ],
    },
    legend: {
      display: false, // no legend desired
    },
    elements: {
      point: {
        // we do not want points to be drawn - need to disable both of these.
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      legend: { display: false }, // no legend desired
      tooltips: {
        enabled: false, // no fly-bys wanted
      },
    },
    scales: {
      y: {
        display: false, // style desired does not have axes displayed
        ticks: {
          // set the y axis to include negative values
          // (25% of the positive max), so annotation line
          // goes through and below zero graph line
          min: -(maxXValue * 0.25),
          max: maxXValue,
        },
      },
      x: {
        display: false, // style desired does not have axes displayed
      },
    },
    responsive: true, // dynamic sizing.
    maintainAspectRatio: false, // we desire sizing per our dimensions, not chart.js aspect ratio
    animation: { duration: 0 }, // general animation time (better performance)
    responsiveAnimationDuration: 0, // animation duration after a resize (better performance)
  };
}

export default {
  name: 'UsagePanel',
  components: { EmbeddedStatusMessage, RSInformationToggle },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    ...mapState({
      usageData: state => state.infoSettings.usageData,
      chartData: state => state.infoSettings.chartData,
      apiType: state => state.infoSettings.apiType || 'general',
      accessCount: state => state.infoSettings.accessCount,
      totalSessionTime: state => state.infoSettings.sessionTime,
      contentCreatedDateLabel: state => state.infoSettings.contentCreatedDateLabel,
      maxAccessCount: state => state.infoSettings.maxAccessCount,
      partial: state => state.infoSettings.partial,
    }),
    loading() {
      return this.usageData === null;
    },
    sessionTimeString() {
      return this.totalSessionTime ? limitedPrecisionDuration(this.totalSessionTime.asSeconds()) : '';
    }
  },
  watch: {
    chartData(newData) {
      // clone the state objects- important! chartjs likes to own its copy :(
      this.chart.data.datasets = newData.datasets.map(dataset => ({ ...dataset }));
      this.chart.data.labels = newData.labels;
      this.chart.options = chartOptions(this.contentCreatedDateLabel, this.maxAccessCount);
      this.chart.update();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeUnmount() {
    this.chart.destroy();
    this.resetChartData();
  },
  methods: {
    ...mapMutations({
      resetChartData: RESET_CHART_DATA,
    }),
    renderChart() {
      this.chart = markRaw(new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'line',
        data: { datasets: [], labels: [] },
        options: chartOptions(this.contentCreatedDateLabel, this.maxAccessCount),
      }));
    },
    usageDocs() {
      return docsPath(
        'cookbook/metrics/'
      );
    }
  }
};
</script>
