<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="contentContainer"
    data-automation="content-frame-container"
    :class="wrapperClass"
  >
    <div
      v-if="variantHasNoRuns"
      data-automation="no-content-page"
      class="blank-container"
    >
      <p class="blank-content-message">
        There are no personal runs for this content.
      </p>
    </div>

    <div v-else>
      <iframe
        v-if="showAppFrame"
        id="contentIFrame"
        ref="embeddedApp"
        :class="['appFrame', { outOfDate: variantIsBusy }]"
        :src="appFrameUrl"
        :title="systemDisplayName"
        data-automation="content-frame"
        height="100%"
        tabindex="-1"
        width="100%"
      />
    </div>

    <div
      v-if="variantIsBusy"
      class="contentMessageContainer"
      data-automation="report-in-progress"
    >
      <div class="genMessage">
        <div class="genMessageSpinnerContainer">
          <Spinner />
        </div>
        Running Report
      </div>
    </div>

    <RenderMessage
      v-if="showRenderMessage"
      :content-type="app.contentType()"
      :is-deployed="appIsReady"
      :is-published="isPublished"
      :is-unrendered="isUnrederedVariant"
      :has-params="isParameterized"
      :user-is-admin="currentUser.isAdmin()"
      :can-view-logs="userCanViewLogs"
      @show-logs="seeAppLogs"
    />
  </div>
</template>

<script>
import {
  SET_CONTENT_FRAME_RELOADING,
  SET_LOGS_PANEL_VISIBILITY,
} from '@/store/modules/contentView';
import { serverURL } from '@/utils/paths';
import { isEmpty } from 'lodash';
import { mapMutations, mapState } from 'vuex';

import Spinner from '@/components/Spinner';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import RenderMessage from './RenderMessage';

export const SHOW_SETTINGS_CLASS = 'showSettingsPanel';
export const HINT_PARAMS_CLASS = 'hintParamsPanel';
export const SHOW_PARAMS_CLASS = 'showParamsPanel';

export default {
  name: 'EmbedApp',
  components: {
    Spinner,
    RenderMessage,
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      appError: state => state.contentView.appError,
      appHistory: state => state.bundles,
      currentUser: state => state.currentUser.user,
      currentVariant: state => state.parameterization.currentVariant,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      isUnauthorized: state => state.contentView.isUnauthorized,
      adhocVariant: state => state.legacyParams.adhocVariant,
      reloadingContentFrame: state => state.contentView.reloadingContentFrame,
      renderingHistory: state => state.contentView.renderingHistory,
      runs: state => state.parameterization.runs,
      serverSettings: state => state.server.settings,
      showParametersPanel: state => state.contentView.showParametersPanel,
      showSettingsPanel: state => state.contentView.showSettingsPanel,
      variantIsBusy: state => state.legacyParams.isBusy || state.parameterization.isBusy,
      variants: state => state.parameterization.variants,
    }),
    appIdOrGuidParam() {
      return this.$route.params.idOrGuid;
    },
    systemDisplayName() {
      return this.serverSettings.systemDisplayName;
    },
    permissionRequestEnabled() {
      return this.serverSettings.permissionRequest;
    },
    useWindowLocation() {
      return this.serverSettings.useWindowLocation;
    },
    newParameterizationEnabled() {
      return this.serverSettings.newParameterizationEnabled;
    },
    wrapperClass() {
      const wClass = [];
      const handleParamDocClasses = (
        !!this.app &&
        this.isParameterized &&
        this.isAuthenticated &&
        this.currentUser.canViewApp(this.app));

      if (this.useShowSettingsClass) {
        wClass.push(SHOW_SETTINGS_CLASS);
      }

      if (handleParamDocClasses) {
        if (this.showParametersPanel) {
          wClass.push(SHOW_PARAMS_CLASS);
        } else {
          wClass.push(HINT_PARAMS_CLASS);
        }
      }

      return wClass;
    },
    useShowSettingsClass() {
      // If app didn't load and user is unauthorized, settings not showing
      // (a non-admin user without app permissions)
      if (!this.app && this.isUnauthorized) {
        return false;
      }

      return this.showSettingsPanel || (
        !this.appIsReady ||
        this.currentUser.isAnonymous()
      );
    },
    userCanViewLogs() {
      return this.currentUser.canViewAppSettings(this.app);
    },
    variantHasNoRuns() {
      return this.newParameterizationEnabled &&
        this.isParameterized &&
        !isEmpty(this.variants) &&
        isEmpty(this.runs) &&
        isEmpty(this.renderingHistory.items);
    },
    appIsReady() {
      return !this.appError && this.app?.isDeployed();
    },
    appFrameUrl() {
      let appUrl = '';

      if (this.showUnauthorizedView || this.currentUser.isAnonymous()) {
        return this.anonUrl;
      }

      if (!this.appIsReady || this.isUnrederedVariant) {
        return '';
      }

      // NOTE: This used to come from `app.url`, but was changed to support
      // customer environments struggling with proxy configuration.
      if (this.useWindowLocation) {
        appUrl = serverURL(`content/${ this.appIdOrGuidParam }/`);
      } else {
        appUrl = this.app.url;
      }

      // Showing a variant
      if (!isEmpty(this.currentVariant)) {
        const variantKey = this.adhocVariant.key || this.currentVariant.key;
        appUrl += `v${variantKey}/`;
        if (this.showHistoricalVariant) {
          appUrl += `_rev${this.renderingHistory.displayedId}/`;
        }
      }

      // Showing a previous render
      if (this.showPreviousBundle) {
        appUrl += `_rev${this.appHistory.displayedId}/`;
      }

      return appUrl;
    },
    showHistoricalVariant() {
      return (
        this.isPublished &&
        isEmpty(this.adhocVariant) &&
        this.currentVariant.renderingId !== this.renderingHistory.displayedId
      );
    },
    showPreviousBundle() {
      return (
        (this.app.isStatic() || this.app.isProxied()) &&
        this.currentUser.isAppEditor(this.app) &&
        this.app.bundleId !== this.appHistory.displayedId
      );
    },
    isUnrederedVariant() {
      return !isEmpty(this.currentVariant) && !this.currentVariant.renderDuration;
    },
    isParameterized() {
      return this.app?.hasParameters;
    },
    isPublished() {
      return !isEmpty(this.currentVariant);
    },
    anonUrl() {
      if (this.currentVariant.renderTime) {
        return serverURL(`content/${ this.appIdOrGuidParam }/v${ this.currentVariant.key }/#__rscembedded__`);
      }
      return serverURL(`content/${ this.appIdOrGuidParam }/#__rscembedded__`);
    },
    showUnauthorizedView() {
      return this.isUnauthorized && this.permissionRequestEnabled;
    },
    showAppFrame() {
      return (
        Boolean(this.appFrameUrl) &&
        !this.reloadingContentFrame &&
        !this.variantIsBusy
      );
    },
    showRenderMessage() {
      return this.app && !this.showAppFrame;
    },
  },
  watch: {
    reloadingContentFrame(reloading) {
      // Content iframe will be removed when "reloading" state is set.
      // Dispatch state reloading to false for the iframe to show up again on next tick.
      if (reloading) {
        this.$nextTick().then(() => this.setFrameReloadState(false));
      }
    },
  },
  created() {
    useSkipToMainContent(this.onSkipToMainContent);
  },
  mounted() {
    document.querySelector('#app')?.focus();
  },
  methods: {
    ...mapMutations({
      setFrameReloadState: SET_CONTENT_FRAME_RELOADING,
      setLogsVisibility: SET_LOGS_PANEL_VISIBILITY,
    }),
    seeAppLogs() {
      this.$router.push({ name: 'apps.logs', replace: true });
      this.setLogsVisibility(true);
    },
    onSkipToMainContent() {
      this.$refs.embeddedApp?.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.blank-container {
  display: flex;
  justify-content: center;
  padding-top: 3.5rem;
}

.blank-content-message {
  font-size: 1.25rem;
}
</style>
