<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import { isEmpty, startCase } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { versionCompare } from '@/utils/version';

const props = defineProps({
  article: { type: Object, default: null },
});

const store = useStore();

const formatPublishedDate = computed(() => dayjs(props.article?.pubDate).format('MMMM D, YYYY'));
const connectThemes = computed(() => props.article.themes.theme?.map((t) => startCase(t)));
const sanitizedContent = computed(() => sanitizeHtml(props.article.content));
const connectVersion = computed(() => store.state.server.settings.version);
const hasConnectVersion = computed(() => !!store.state.server.settings.version);
const hasConnectThemes = computed(() => !isEmpty(connectThemes.value));
const hasThumbnail = computed(() => !!props.article.thumbnail);
const isAdmin = computed(() => store.state.currentUser.user.isAdmin());
const requiresNewerConnectVersion = computed(() => {
  return versionCompare(props.article.minimumConnectVersion, connectVersion.value) === 1;
});
const showReminder = computed(() => requiresNewerConnectVersion.value && props.article.minimumConnectVersion !== '0.0.0');

const connectVersioningMessage = computed(() => {
  const baseMessage = `Posit Connect version ${props.article.minimumConnectVersion} or later.`;

  if (!hasConnectVersion.value) { return baseMessage; }

  if (requiresNewerConnectVersion.value && isAdmin.value) {
    return `${baseMessage} To upgrade, see https://docs.posit.co/connect/admin/upgrade/`;
  };

  if (requiresNewerConnectVersion.value && !isAdmin.value) {
    return `${baseMessage} Contact your Connect administrator to upgrade.`;
  }
  return null;
});
</script>

<template>
  <article
    :key="props.article.guid"
    data-automation="whats-new-feed"
    class="news-article"
  >
    <div class="news-article__body">
      <h2
        class="news-article__title"
        data-automation="connect-news-article-title"
      >
        {{ props.article.title }}
      </h2>
      <time
        :datetime="props.article.pubDate"
        data-automation="connect-news-article-date"
        class="news-article__date"
      >
        {{ formatPublishedDate }}
      </time>
      <!-- The content article.content is sanitized by the sanitize-html -->
      <!-- We still want to render safe HTML elements in the content. -->
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="news-article__content"
        data-automation="connect-news-article-content"
        v-html="sanitizedContent"
      />
      <!-- eslint-enable vue/no-v-html -->
      <footer
        v-if="showReminder || hasConnectThemes"
      >
        <p
          v-if="showReminder"
          data-automation="connect-news-article-version"
          class="news-article__versioning"
        >
          <span>Requires:</span>
          {{ connectVersioningMessage }}
        </p>
        <ul
          v-if="hasConnectThemes"
          class="news-article__themes"
        >
          <li
            v-for="(theme, index) in connectThemes"
            :key="index"
            data-automation="connect-news-article-theme"
            class="news-article__theme"
          >
            {{ theme }}
          </li>
        </ul>
      </footer>
    </div>
    <div
      v-if="hasThumbnail"
      class="news-article__thumbnail-container"
      data-automation="connect-news-article-thumbnail"
    >
      <img
        :src="props.article.thumbnail"
        alt="Connect News Article Image"
        class="news-article__thumbnail"
      >
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.news-article {
  background: $color-white;
  border: 1px solid $color-default-border;
  border-radius: 4px;
  display: flex;
  margin-bottom: 16px;
  max-width: 1200px;
  padding: 16px 16px 8px 16px;

  &__body {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin-right: 24px;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 26px;
  }

  &__date {
    display: block;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 16px;
  }

  &__content {
    flex-grow: 1;
    font-size: 1rem;
    line-height: 26px;

    // Styles for the headings within the content
    :is(h1, h2, h3, h4, h5) {
      font-size: 1.125rem;
      font-weight: 600;
      margin-top: 1rem;
    }

    // Styles for the lists within the content
    :is(ul, ol) {
      list-style-type: disc;
      margin-bottom: 0.875rem;
      padding-left: 30px;
    }

     // Styles for the list items within the content
    :is(li) {
      margin-bottom: 0;
    }

    // Styles for paragraphs within the content
    :is(p) {
      margin-bottom: 0.875rem;
    }
  }

  &__thumbnail-container {
    max-width: 350px;
  }

  &__thumbnail {
    border: 1px solid $color-default-border;
    border-radius: 5px;
    object-fit: fill;
    width: 100%;
  }

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &__versioning {
    background-color: $color-reminder-background;
    border-left: 5px solid $color-reminder-background-border;
    padding: 9px;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 13px;

    span {
      font-weight: bold;
    }
  }

  &__themes {
    display: flex;
  }

  &__theme {
    background: $color-light-blue-2;
    border: 1px solid $color-default-border;
    border-radius: 4px;
    line-height: 26px;
    margin-bottom: 0;
    margin-right: 14px;
    padding: 0 5px 0 5px;
  }

  footer {
    margin-top: 13px;
  }
}
</style>
