<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<!--
  The :options prop
  Expects an Array of object-options, each option can have nested children-options.
  The required keys for each option are "id", "label" and "checked".
  {
  id:       Number|String. Unique identifier for the option
  label:    String. Used as the option label.
  checked:  Boolean. To mark as checked or not on the initial render.
  }

  The "label" can be repeated on different levels of the options tree,
  but must be unique on the same branch/level.

  Aside from "label" and "checked", each option can hold more key-values that will
  be provided on selected items when @change triggers. E.g:
  [
  {
  id: 25,
  tagId: 100,
  authorId: 100,
  label: 'Industrial Materials',
  checked: false,
  },
  {
  ...
  ]
  When selecting the "Industrial Materials" options, the @change event payload will
  come with tagId and authorId to be used in a way that may suit your needs.
  @change [
  {
  id: 25,
  tagId: 100,
  authorId: 100,
  label: 'Industrial Materials',
  }
  ]

  For more examples you can look at the tests RSCheckboxGroup.test.js
-->

<template>
  <div class="rs-checkboxgroup">
    <div
      v-if="!hasHelp && title"
      class="rs-field__info"
    >
      <div class="rs-field__info-label">
        {{ title }}
      </div>
    </div>
    <RSInformationToggle v-if="hasHelp">
      <template
        v-if="title"
        #title
      >
        <span class="rs-field__info-label">{{ title }}</span>
      </template>
      <template #help>
        <span v-if="$slots.help">
          <slot name="help" />
        </span>
        <span v-else>{{ help }}</span>
      </template>
    </RSInformationToggle>

    <div
      v-for="(option, index) in optionsTree"
      :key="option.id"
      class="rs-checkboxgroup__group"
    >
      <RSInputCheckbox
        v-model="option.checked"
        :name="name + '-' + option.id"
        :label="option.label"
        :disabled="readOnly"
        @change="updateSelection($event, index)"
      />
      <RSCheckboxGroup
        v-if="option.checked && option.children"
        :name="name + '-' + option.id"
        :options="option.children"
        :read-only="readOnly"
        @change="updateBranchChildren($event, index, option.id)"
      />
    </div>
  </div>
</template>

<script>
import RSInformationToggle from './RSInformationToggle.vue';
import RSInputCheckbox from './RSInputCheckbox';

export default {
  name: 'RSCheckboxGroup',
  components: {
    RSInformationToggle,
    RSInputCheckbox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: null
    },
  },
  emits: ['change'],
  data() {
    return {
      optionsTree: this.options,
    };
  },
  computed: {
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
  },
  methods: {
    copyWithoutChildren(option) {
      const selection = { ...option };
      delete selection.checked;
      selection.hasChildren = Boolean(
        option.children && option.children.length
      );
      if (option.children) {
        selection.children = [];
      }
      return selection;
    },
    traverseBranchSelections(options, currentMappedBranch = {}) {
      const selectionTree = [];
      options.forEach((option, index) => {
        if (option.checked) {
          const mappedOption = this.copyWithoutChildren(option);
          if (option.children) {
            if (currentMappedBranch.payload && currentMappedBranch.branchIndex === index) {
              mappedOption.children = currentMappedBranch.payload;
            } else {
              mappedOption.children = this.traverseBranchSelections(option.children);
            }
          }
          selectionTree.push(mappedOption);
        }
      });
      return selectionTree;
    },
    async updateSelection(checked, optionsIndex) {
      // Wait on tree model to update before building and emitting the change
      await this.$nextTick();

      const optionInTree = this.options[optionsIndex];
      const hasChildren = Boolean(optionInTree.children && optionInTree.children.length);
      const selectionTree = this.traverseBranchSelections(this.optionsTree);
      let deselectedChildren = [];

      if (!checked) {
        deselectedChildren = this.uncheckDeepSelections(optionsIndex);
      }

      const payload = this.buildEventPayload({
        optionsIndex,
        hasChildren,
        selectionTree,
        deselectedChildren,
      });

      this.$emit('change', payload);
    },
    async updateBranchChildren(ev, branchIndex, currentParentId) {
      const {
        target,
        checked,
        hasChildren,
        selectionTree,
        parentId,
        deselectedChildren,
      } = ev;

      // Wait on tree model to update before building and emitting the change
      await this.$nextTick();
      const newSelectionTree = this.traverseBranchSelections(
        this.optionsTree,
        {
          branchIndex,
          payload: selectionTree,
        }
      );

      const payload = this.buildEventPayload({
        target,
        checked,
        hasChildren,
        parentId: parentId || currentParentId,
        selectionTree: newSelectionTree,
        deselectedChildren,
      });

      this.$emit('change', payload);
    },
    uncheckDeepSelections(optionsIndex) {
      const deselectedChildren = [];
      const optionsBranch = this.optionsTree[optionsIndex];
      const resetDeepSelections = children => {
        children.forEach(item => {
          if (item.checked) {
            deselectedChildren.push(item.id);
          }
          item.checked = false;
          if (item.children) {
            resetDeepSelections(item.children);
          }
        });
      };
      if (optionsBranch.children) {
        resetDeepSelections(optionsBranch.children);
      }
      return deselectedChildren;
    },
    buildEventPayload({
      target,
      checked,
      optionsIndex,
      hasChildren,
      parentId = null,
      selectionTree = [],
      deselectedChildren = [],
    }) {
      const payload = {
        target,
        checked,
        parentId,
        hasChildren,
        selectionTree,
        deselectedChildren,
      };
      if (optionsIndex !== undefined) {
        const item = this.optionsTree[optionsIndex];
        payload.target = item.id;
        payload.checked = item.checked;
      }
      return payload;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-checkboxgroup {
   margin-bottom: 0;

  .rs-field {
    margin-bottom: 0;

    &__info {
      @include message;
      font-size: 0.9rem;
      font-weight: 400;

      &-label {
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: .2rem;
      }
    }
  }

  &__group {
    // Deep-nested Cascade styles
    .rs-checkboxgroup {
      margin-bottom: 0;
      border-left: 1px solid $color-light-grey-3;
      margin-left: 6px;
      padding-left: 1rem;
    }
  }
}
</style>
