<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rsc-search-box">
    <RSInputSearch
      v-model="form.search"
      :data-automation="dataAutomation"
      icon="search"
      :label="label"
      :name="name"
      :search-results="searchResults"
      :show-label="false"
      @change="onInput"
      @select="onSelect"
    >
      <template #result="principal">
        <RSPrincipalInfo
          :is-group="isGroup(principal)"
          :initials="principal.displayInitials"
          :name="principal.displayName"
          :additional-icon="viewerIcon(principal)"
          :tooltip="viewerTooltip(principal)"
          :status="principal.username"
        />
      </template>
      <template #mode>
        <ModeSelector />
      </template>
    </RSInputSearch>
  </div>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import RSInputSearch from '@/elements/RSInputSearch.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import { ModeType } from '@/store/modules/accessSettings';
import { mapState } from 'vuex';
import ModeSelector from './ModeSelector';

export default {
  name: 'SearchBox',
  components: {
    ModeSelector,
    RSInputSearch,
    RSPrincipalInfo,
  },
  extends: PrincipalSearchSelect,
  props: {
    exclusionSet: {
      type: Set,
      default() { return new Set(); },
    },
  },
  emits: ['select'],
  computed: {
    ...mapState({
      mode: state => state.accessSettings.mode,
    }),
    isCollaborator() {
      return this.mode === ModeType.OWNER || this.mode === ModeType.EDITOR;
    },
    searchResults() {
      return this.api.principals.filter(user => !this.exclusionSet.has(user.guid));
    },
  },
  methods: {
    isViewer(principal) {
      return this.isUser(principal)
        ? principal.userRole === UserRoles.Viewer
        : false;
    },
    addAsViewer(principal) {
      return this.isCollaborator && this.isViewer(principal);
    },
    viewerIcon(principal) {
      return this.addAsViewer(principal) ? 'viewer' : null;
    },
    viewerTooltip(principal) {
      return this.addAsViewer(principal) ? 'Viewer' : '';
    },
    onSelect(principal) {
      const mode = this.addAsViewer(principal) ? ModeType.VIEWER : this.mode;
      this.$emit('select', { principal, mode });
      this.api.principals = [];
      this.form.search = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.rsc-search-box {
  margin-bottom: 0.9rem;
  :deep(.rs-input) {
    border: 0px;
  }
}
</style>
