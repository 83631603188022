<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <div class="fullPageFormContainer">
        <h1
          v-if="isFormCompleted"
          class="formTitle"
          data-automation="reset-password-email-sent"
        >
          Password Reset Email Sent
        </h1>
        <h1
          v-else
          class="formTitle"
          data-automation="reset-password-instructions"
        >
          Reset Password
        </h1>

        <form
          v-if="serverSettings.mailConfigured"
          autocomplete="off"
          @submit.prevent="handleSubmit"
        >
          <div
            v-if="isFormCompleted"
            class="instructions"
            data-automation="reset-password-check-email"
          >
            Check your e-mail for instructions on resetting your password.
            Please contact your {{ serverSettings.systemDisplayName }} administrator
            for assistance if your username is correct but you have not
            received an email after a few minutes.
          </div>

          <div
            v-else
            class="instructions"
            data-automation="reset-password-enter-username"
          >
            Enter your username and select Reset Password.
            An email will be sent to the address registered
            to your account with further instructions.
          </div>
          <div v-if="!isFormCompleted">
            <fieldset class="chunk">
              <!-- eslint-disable vuejs-accessibility/no-autofocus -->
              <RSInputText
                ref="username"
                v-model.trim="username"
                autofocus
                name="username"
                label="Username"
                :message-type="getError.type"
                :message="getError.message"
                @change="isFormDirty = true"
              />
            </fieldset>

            <div class="actions">
              <RSButton
                id="resetPassword"
                type="primary"
                label="Reset Password"
                :disabled="shouldDisableResetPasswordButton"
              />
            </div>
          </div>
        </form>

        <div
          v-else
          class="formish"
        >
          <p
            class="instructions"
            data-automation="reset-password-ask-your-admin"
          >
            Ask your {{ serverSettings.systemDisplayName }} administrator to
            obtain a
            <span class="emphasize">
              password reset link
            </span>
            from your user profile for you.
            By visiting this link you will be able to set a new password.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendOrGetResetPasswordLink } from '@/api/authentication';
import RSButton from '@/elements/RSButton.vue';
import RSInputText from '@/elements/RSInputText.vue';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ResetPasswordView',
  components: {
    RSInputText,
    RSButton,
  },
  data() {
    return {
      username: '',
      isFormDirty: false,
      isFormCompleted: false,
      isLoading: false,
    };
  },
  computed: {
    isValidForm() {
      return Boolean(this.username.trim().length);
    },
    getError() {
      if (!this.isValidForm && this.isFormDirty) {
        return {
          message: 'This field cannot be blank.',
          type: 'error',
        };
      }

      return { message: null, type: null };
    },
    shouldDisableResetPasswordButton() {
      return !this.isValidForm || this.isLoading;
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.setUsername();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    setUsername() {
      const urlParams = new URLSearchParams(location.hash.split('?')[1]);
      const username = (urlParams.get('u') || '').trim();
      if (username) {
        this.username = username;
      }
    },
    handleSubmit() {
      if (this.shouldDisableResetPasswordButton) {
        return;
      }

      this.isLoading = true;
      this.clearStatusMessage();
      sendOrGetResetPasswordLink(this.username)
        .then(() => {
          this.isFormCompleted = true;
        })
        .catch(error => {
          this.setErrorMessageFromAPI(error);
          this.$refs.username.$el.querySelector('input').focus();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.pushFooter {
  position: relative;
  min-height: 100%;
  margin-bottom: -$footer-height;

  &:after {
    content: "";
    height: $footer-height;
    display: block;
  }
}

.fullPageFormContainer {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  form, div.formish {
    background-color: $color-light-grey;
    padding: 20px 40px 40px 40px;

    .actions {
      text-align: right;
      white-space: nowrap;
      margin-top: 30px;
    }

    .chunk {
      margin-bottom: 20px;
    }

    .instructions {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .formTitle {
    text-align: center;
    padding: 25px 30px;
    font-size: 18px;
    background-color: $color-light-grey;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #fff;
  }
}

@media screen and (max-width: 420px){

  .fullPageFormContainer {
    max-width: 400px;

    form, div.formish {
      padding: 20px;
    }

    .formTitle {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
