<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';
</script>

<script setup>
import { computed, onMounted, useAttrs, useSlots } from 'vue';
import RSInformationToggle from './RSInformationToggle.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  dataAutomation: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: '',
  },
  help: {
    type: String,
    default: null
  },
  message: {
    type: String,
    default: null
  },
  messageType: {
    type: String,
    default: ErrorMessage,
  },
});

const emit = defineEmits(['change', 'update:modelValue']);
const attrs = useAttrs();
const slots = useSlots();

const hasMessage = computed(() => Boolean(props.message) || Boolean(slots.message));
const hasHelp = computed(() => Boolean(props.help) || Boolean(slots.help));
const hasError = computed(() => hasMessage.value && props.messageType === ErrorMessage);
const hasWarning = computed(() => hasMessage.value && props.messageType === WarningMessage);
const hasInfo = computed(() => hasMessage.value && props.messageType === InfoMessage);

onMounted(() => {
  if (!props.label && !slots.default) {
    throw new Error(`RSInputCheckbox[${props.name}]: A label must be provided as either a property or a slot.`);
  }
  if (props.label && slots.default) {
    console.warn(`RSInputCheckbox[${props.name}]: Both a label and a default slot have been provided. The default slot will be ignored.`);
  }
});

const handleValue = (ev) => {
  emit('change', ev.target.checked);
  emit('update:modelValue', ev.target.checked);
};
</script>

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <RSInformationToggle v-if="hasHelp">
      <template #title>
        <label
          :for="name"
          class="rs-checkbox__label"
        >
          <input
            v-bind="attrs"
            :id="name"
            class="rs-checkbox__input"
            type="checkbox"
            :checked="modelValue"
            @change="handleValue"
          >
          <span v-if="label">{{ label }}</span>
          <slot v-else />
        </label>
      </template>
      <template #help>
        <span v-if="slots.help">
          <slot name="help" />
        </span>
        <span v-else>{{ help }}</span>
      </template>
    </RSInformationToggle>
    <label
      v-else
      :for="name"
      class="rs-checkbox__label"
    >
      <input
        v-bind="attrs"
        :id="name"
        :name="name"
        class="rs-checkbox__input"
        type="checkbox"
        :checked="modelValue"
        :aria-describedby="`${ name }-message`"
        :aria-invalid="hasError"
        @change="handleValue"
      >
      <span v-if="label">{{ label }}</span>
      <slot v-else />
    </label>

    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      <span v-if="slots.message">
        <slot name="message" />
      </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }
}

.rs-checkbox {
  &__input{
    box-sizing: border-box;
    padding: 5px 10px;
    margin: 2px 6px 2px 2px;
    border: none;

    appearance: none;
    -webkit-appearance: none;
    background-color: $color-white;
    border-radius: 1px;
    border: 2px solid $color-white;
    outline: 2px solid $color-dark-grey;
    padding: 6px;
    vertical-align: top;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:checked {
      background-color: $color-selected;
      outline: 2px solid $color-selected;

      &:disabled {
        background-color: $color-dark-grey;
        outline: 2px solid $color-dark-grey;
      }

      &:after {
        display: block;
        top: 7px;
        left: 7px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    &:focus {
      outline: 2px solid $color-posit-teal;
    }

    &:disabled {
      @include control-disabled-input;
      cursor: not-allowed;
    }
  }

  &__label {
    position: relative;
    display: inline-block;
    font-size: $rs-font-size-small;
    line-height: 1.5;
    cursor: pointer;
    padding: 3px 0;

    &:has(:disabled) {
      cursor: not-allowed;
    }

    & + .rs-field__error {
      margin-top: unset;
    }
    & + .rs-field__warning {
      margin-top: unset;
    }

    & + .rs-field__info {
      margin-top: unset;
    }
  }
}
</style>
