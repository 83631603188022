<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Renders the routable system checks vue container -->
<template>
  <div>
    <div class="rsc-systemchecks__header">
      <div
        class="flex"
        data-automation="system-checks"
      >
        <h1 class="sectionTitle focusedTitle">
          System Checks
        </h1>
      </div>
      <RunNewReportButton
        @new-report="getReports"
      />
    </div>
    <div>
      {{ `All times are displayed in local time (${offset})` }}
    </div>
    <div
      v-if="displayTable"
      class="systemChecksTable"
    >
      <RSTable
        :columns="colNames"
      >
        <RSTableRow
          v-for="(report, i) in reports"
          :key="i"
          :row-id="report.id"
          :row-label="`Report Generated ${report.startTime.toLocaleString()}`"
          :report="report"
          :class="[{highlighted: (i == selectionIndex)}, 'clickable']"
          data-automation="system-checks-report-table-row"
          clickable
          @click="selectReport(i)"
        >
          <RSTableCell>
            {{
              new Intl.DateTimeFormat(undefined, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short' }).format(report.startTime)
            }}
          </RSTableCell>
          <RSTableCell>
            {{ report.hostname }}
          </RSTableCell>
          <RSTableCell>
            {{ resultString(report) }}
          </RSTableCell>
        </RSTableRow>
      </RSTable>
    </div>
    <div
      v-if="displayTable"
    >
      <div
        v-if="reports[selectionIndex]"
      >
        <div class="statusReportToolbar">
          <RSButton
            label="Download Selected Report"
            type="secondary"
            :class="{ disabled: !displaySelectedReport }"
            data-automation="download-system-checks-report-button"
            @click="downloadReport"
          />
          <RSButton
            :label="displaySelectedReport ? 'Delete Selected Report' : 'Cancel Selected Report'"
            type="secondary"
            data-automation="delete-system-checks-report-button"
            @click="deleteSelectedReport"
          />
        </div>
        <hr>
        <SystemCheckRunReport
          v-if="displaySelectedReport"
          ref="reportDocument"
          :report="reports[selectionIndex]"
        />
        <div
          v-if="!displaySelectedReport"
          class="report-message"
        >
          <h3>
            The selected report will display when all associated tests have finished running.
          </h3>
        </div>
      </div>
      <div
        v-if="!reports[selectionIndex]"
        class="report-message"
      >
        <hr>
        <h3>
          Please select a report.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteSystemCheckRun, getSystemCheckRuns } from '@/api/systemChecks';
import RSButton from '@/elements/RSButton.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import pluralize from '@/utils/pluralize';
import { localOffset } from '@/utils/timezone';
import { mapMutations } from 'vuex';
import RunNewReportButton from './systemChecks/RunNewReportButton';
import SystemCheckRunReport from './systemChecks/SystemCheckRunReport';
import { makeReportDocumentHTML } from './systemChecks/utils';

export default {
  name: 'SystemChecksView',
  components: {
    RSButton,
    SystemCheckRunReport,
    RSTable,
    RSTableRow,
    RSTableCell,
    RunNewReportButton,
  },
  data() {
    return {
      reports: [],
      offset: localOffset(),
      selectionIndex: null,
    };
  },
  computed: {
    displayTable() {
      return this.reports.length > 0;
    },
    displaySelectedReport() {
      return ['done', 'canceled'].includes(this.reports[this.selectionIndex].status);
    }
  },
  created() {
    const columnLabels = {
      startTime: 'Start Time',
      hostname: 'Hostname',
      status: 'Status',
    };
    this.getReports();
    this.interval = setInterval(() => {
      this.getReports();
    }, 5000);
    this.colNames = ['startTime', 'hostname', 'status'].map(col => {
      return {
        label: columnLabels[col],
        width: '33.33%',
      };
    });
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    getReports() {
      return getSystemCheckRuns()
        .then(res => {
          res.sort(function(a, b) {
            const keyA = new Date(a.startTime), keyB = new Date(b.startTime);
            // Compare the 2 dates
            if (keyA < keyB) { return 1; }
            if (keyA > keyB) { return -1; }
            return 0;
          });
          this.reports = res || [];
        })
        .catch(err => {
          this.setErrorMessageFromAPI(err);
          this.reports = [];
        });
    },
    async deleteSelectedReport() {
      try {
        await deleteSystemCheckRun(this.reports[this.selectionIndex].id);
        this.getReports();
      } catch (error) {
        this.setErrorMessageFromAPI(error);
      }
    },
    selectReport(i) {
      this.selectionIndex = i;
    },
    downloadReport() {
      const doc = makeReportDocumentHTML(this.$refs.reportDocument.$el.outerHTML);
      const report = new Blob([doc], {
        type: 'application/html'
      });
      const filedate = this.reports[this.selectionIndex].startTime.toJSON().slice(0, 19)
        .replace(/[/\\?%*:|"<>]/g, '.');
      const filename = `rsc-system-checks-report-${this.reports[this.selectionIndex].hostname}-${filedate}.html`;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(report);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    resultString(report) {
      const passedSymbol = '✅';
      const failedSymbol = '⚠️';
      let resultString = '';
      switch (report.status) {
        case 'requested':
          resultString = 'Waiting to start…';
          break;
        case 'canceled':
          resultString = 'Canceled';
          break;
        case 'running':
          resultString = 'Running tests… ';
        // eslint-disable-next-line no-fallthrough
        case 'done':
          if (report.passed > 0) {
            resultString = resultString.concat(`${passedSymbol} ${report.passed} passed`);
          }
          if (report.passed > 0 && report.failed > 0) {
            resultString = resultString.concat(', ');
          }
          if (report.failed > 0) {
            resultString = resultString.concat(`${failedSymbol} ${report.failed} ${pluralize(report.failed, 'warning', 'warnings')}`);
          }
      }
      return resultString;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.rsc-systemchecks__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.systemChecksTable {
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 1rem;
}

.clickable {
  @include clickable-table-cell($color-light-grey, 250ms);
}

.clickable.highlighted {
    background-color: $color-light-blue;
}

.statusReportToolbar {
  margin: 14px 0px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  column-gap: 10px;
}

.rs-button {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.report-message {
  margin-top: 20px;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500;
  }
}
</style>
