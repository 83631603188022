<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import UserRoles from '@/api/dto/userRole';
import { searchUsers } from '@/api/users';
import CopyTextDialog from '@/components/CopyTextDialog';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ImportRemoteEntityDialog from '@/components/ImportRemoteEntityDialog';
import Paginator from '@/components/Paginator.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import { activeTime } from '@/utils/activeTime.filter';
import { userPath } from '@/utils/paths';
import upperFirst from 'lodash/upperFirst';
import { computed, nextTick, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import UserCreateDialog from './UserCreateDialog';
import UsersOptionPanel from './UsersOptionPanel';

const store = useStore();

const addUserButton = ref(null);
const optionsButton = ref(null);
const optionsPanel = ref(null);

const pageSize = 10;

const tableHeaders = [
  { name: 'user', label: 'User', width: '100%' },
  { name: 'role', label: 'Role' },
  { name: 'lastActive', label: 'Last Active' },
];

const localState = reactive({
  initialDataLoaded: false,
  loading: false,
  optionsPanelVisible: true,
  addUserDialogVisible: false,
  confirmationLinkDialogVisible: false,
  confirmationLink: null,
  searchParameters: {
    prefix: '',
    accountStatus: {},
    userRole: UserRoles.Anonymous,
    currentPage: 1,
  },
  api: {
    users: [],
    total: 0,
    totalPages: 1,
  },
  highlightFlag: false,
});

const highlightClass = computed(() => {
  // Use a class that handles the highlight of new rows
  return rowIndex =>
    localState.highlightFlag && rowIndex === 0 ? 'highlight-once' : '';
});

const isLoading = computed(
  () => !localState.initialDataLoaded || localState.loading
);
const pageTitle = computed(() =>
  localState.searchParameters.prefix ? 'Matching Users' : 'Users');

const enableAddUsersBtn = computed(
  () => canAddNewUser.value || canAddRemoteUser.value
);
const canAddNewUser = computed(
  () =>
    localState.initialDataLoaded &&
    currentUser.value.canAddNewUser(serverSettings.value)
);
const canAddRemoteUser = computed(
  () =>
    localState.initialDataLoaded &&
    currentUser.value.canAddRemoteUser(serverSettings.value)
);
const canSearchById = computed(() =>
  Boolean(serverSettings.value.authentication.externalUserId));
const showOptionsPanel = computed(
  () =>
    currentUser.value &&
    serverSettings.value &&
    (currentUser.value.userRole >= UserRoles.Publisher ||
      !serverSettings.value.viewersCanOnlySeeThemselves)
);
const showOptionsPanelToggleButton = computed(
  () => !localState.optionsPanelVisible && showOptionsPanel.value
);
const showPager = computed(
  () => !localState.loading && localState.api.totalPages > 1
);

const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);

onBeforeMount(() => {
  init();
});

const setErrorMessageFromAPI = err => {
  store.commit('SET_ERROR_MESSAGE_FROM_API', err);
};

const init = async() => {
  try {
    await search();
    localState.initialDataLoaded = true;
    localState.optionsPanelVisible =
      currentUser.value.userRole >= UserRoles.Publisher ||
      !serverSettings.value.viewersCanOnlySeeThemselves;
  } catch (err) {
    setErrorMessageFromAPI(err);
  }
};
const search = () => {
  const {
    searchParameters: { prefix, accountStatus, userRole, currentPage },
  } = localState;
  const timeoutId = setTimeout(() => (localState.loading = true), 300);

  return searchUsers(serverSettings.value, {
    prefix,
    accountStatus,
    userRole,
    includeRemote: false,
    pageNumber: currentPage,
  })
    .then(({ results, currentPage: newCurrentPage, total, totalPages }) => {
      localState.searchParameters.currentPage = newCurrentPage;
      localState.api.users = results;
      localState.api.total = total;
      localState.api.totalPages = totalPages;
    })
    .catch(setErrorMessageFromAPI)
    .finally(() => {
      clearTimeout(timeoutId);
      localState.loading = false;
    });
};
const userProfileLink = user => ({
  title: user.displayName,
  href: userPath(user.guid),
});

const userRoleName = user => upperFirst(UserRoles.stringOf(user.userRole));

const toggleOptionsPanel = async() => {
  localState.optionsPanelVisible = !localState.optionsPanelVisible;
  await nextTick();
  const focused = localState.optionsPanelVisible
    ? optionsPanel
    : optionsButton;
  focused.value.focus();
};

const toggleAddUserDialog = async() => {
  localState.addUserDialogVisible = !localState.addUserDialogVisible;
  if (!localState.addUserDialogVisible) {
    await nextTick();
    addUserButton.value.focus();
  }
};

const toggleConfirmationLinkDialog = link => {
  if (link) {
    localState.confirmationLink = link;
  }
  localState.confirmationLinkDialogVisible =
    !localState.confirmationLinkDialogVisible;
};

const updateSearchOptions = ({ prefix, accountStatus, userRole }) => {
  localState.searchParameters.currentPage = 1;
  localState.searchParameters.prefix = prefix;
  localState.searchParameters.accountStatus = accountStatus;
  localState.searchParameters.userRole = userRole;
  return search();
};

const removeUserFromTable = targetGUID => {
  const index = localState.api.users.findIndex(
    user => user.guid === targetGUID
  );
  if (index !== -1) {
    localState.api.users.splice(index, 1);
  }
};

const userImported = user => {
  // Evaluate if remote user already exists
  // and try to remove it from current page if needed
  // (to be placed on top for user to see it)
  if (user.guid) {
    removeUserFromTable(user.guid);
  }
  userCreated(user);
};

const userCreated = user => {
  localState.api.users.unshift(user);
  toggleAddUserDialog();
  highlightNewUser();
};

const userActiveTime = user => activeTime(user.activeTime);

const highlightNewUser = () => {
  localState.highlightFlag = true;
  setTimeout(() => {
    localState.highlightFlag = false;
  }, 1_000);
};

const gotoPage = pageNumber => {
  localState.searchParameters.currentPage = pageNumber;
  search();
};
</script>

<template>
  <div
    :class="localState.optionsPanelVisible ? '' : 'hideOptionsPanel'"
    class="contentWithOptionsPanel"
  >
    <div
      id="usersPanel"
      class="majorColumn"
    >
      <div class="sectionTitle flex">
        <div
          data-automation="uv-title"
          class="title"
          role="heading"
          aria-level="1"
        >
          {{ pageTitle }}
        </div>
        <div
          v-if="localState.initialDataLoaded"
          class="actionBar inline showTitles"
        >
          <button
            v-if="enableAddUsersBtn"
            ref="addUserButton"
            data-automation="add-user-button"
            aria-label="Add User"
            type="button"
            class="action new"
            @click="toggleAddUserDialog"
          >
            <span class="hideOnMobile">Add User</span>
          </button>
          <button
            v-if="showOptionsPanelToggleButton"
            ref="optionsButton"
            aria-label="Options"
            type="button"
            class="action toggleOptions"
            @click="toggleOptionsPanel"
          >
            <span class="hideOnMobile">Options</span>
          </button>
        </div>
      </div>

      <EmbeddedStatusMessage
        v-if="isLoading"
        :show-close="false"
        message="Getting users..."
        type="activity"
      />

      <RSTable
        v-show="!isLoading"
        :columns="tableHeaders"
        data-automation="users__list"
      >
        <RSTableRow
          v-for="(user, index) in localState.api.users"
          :key="index"
          :class="highlightClass(index)"
          :row-id="user.guid"
          :row-label="`Go to ${user.displayName}'s profile`"
        >
          <RSTableCell
            :cell-id="`user-${user.guid}-link`"
            :has-icon="true"
            :fill="true"
            :link="userProfileLink(user)"
            :clickable="true"
            data-automation="user-link"
          >
            <RSPrincipalInfo
              :initials="user.displayInitials"
              :name="user.displayName"
              :status="user.displayStatuses"
            />
          </RSTableCell>
          <RSTableCell>{{ userRoleName(user) }}</RSTableCell>
          <RSTableCell>
            {{ userActiveTime(user) }}
          </RSTableCell>
        </RSTableRow>
      </RSTable>

      <Paginator
        v-show="showPager"
        :page="localState.searchParameters.currentPage"
        :total="localState.api.total"
        :per-page="pageSize"
        @change="gotoPage"
      />
    </div>

    <div
      v-if="localState.initialDataLoaded && showOptionsPanel"
      class="minorColumn"
    >
      <UsersOptionPanel
        v-if="localState.optionsPanelVisible"
        ref="optionsPanel"
        :is-admin="currentUser.isAdmin()"
        :can-search-by-id="canSearchById"
        @close="toggleOptionsPanel"
        @change="updateSearchOptions"
      />
    </div>

    <UserCreateDialog
      v-if="localState.addUserDialogVisible && canAddNewUser"
      :server-settings="serverSettings"
      :current-user-role="currentUser.userRole"
      @close="toggleAddUserDialog"
      @user-created="userCreated"
      @confirmation-link="toggleConfirmationLinkDialog"
    />
    <CopyTextDialog
      v-if="localState.confirmationLinkDialogVisible && localState.confirmationLink"
      title="Account Confirmation Link"
      info-message="Successfully added user"
      description="Pass the following account confirmation link along to the user without visiting it."
      copied-message="The account confirmation link has been copied to the clipboard."
      text-label="Confirmation Link"
      :text="localState.confirmationLink"
      @close="toggleConfirmationLinkDialog"
    />
    <ImportRemoteEntityDialog
      v-if="localState.addUserDialogVisible && canAddRemoteUser"
      type="user"
      :server-settings="serverSettings"
      @import="userImported"
      @close="toggleAddUserDialog"
    />
  </div>
</template>

<style lang="scss">
@import 'Styles/shared/_mixins';
@import 'Styles/shared/_variables';

.contentWithOptionsPanel {
  @include flex-space-between();
  align-items: flex-start;

  .majorColumn {
    width: $major-column-width;
    overflow-x: auto;
    min-height: 500px;
  }

  .minorColumn {
    width: $minor-column-width;
    min-width: $min-minor-column-width;
    padding-left: $major-minor-padding;
  }

  &.hideOptionsPanel {
    .majorColumn {
      width: 100%;
    }
    .minorColumn {
      display: none;
    }
  }
}

@include for-large-screens() {

  .contentWithOptionsPanel {
    .majorColumn {
      width: 99.9%;
    }
    .minorColumn {
      width: 0.1%;
      min-width: 1px;
    }
  }
}

// Overwrite link text-decoration to this specific table links
.rs-tablecell {
  a:hover {
    text-decoration: none;
  }
}

.title {
  padding: 2px;
  margin-left: 2px;
}

.highlight-once {
  @include highlight-once();
}

.toggleOptions {
  background-image: url(Images/elements/actionToggleSettings.svg);
}

.new {
  background-image: url(Images/elements/actionNew.svg);
}
</style>
