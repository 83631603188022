<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="History"
    title="History"
    class="action outputHistory content-actions__history-button"
    data-automation="menuitem-history--dropdown"
    @click="onToggleHistory"
  >
    <span class="actionTitle">History</span>
  </button>
</template>

<script>
import { TOGGLE_BUNDLES_HISTORY } from '@/store/modules/bundles';
import { TOGGLE_RENDERINGS_HISTORY } from '@/store/modules/contentView';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'HistoryButton',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
  },
  methods: {
    ...mapMutations({
      toggleBundlesHistory: TOGGLE_BUNDLES_HISTORY,
      toggleRenderingsHistory: TOGGLE_RENDERINGS_HISTORY,
    }),
    onToggleHistory() {
      if (this.app.isStatic() || this.app.isProxied()) {
        this.toggleBundlesHistory();
      } else {
        this.toggleRenderingsHistory();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outputHistory {
  background-image: url(Images/elements/actionOutputHistory.svg);
}
</style>
