<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    aria-label="Settings"
    title="Settings"
    class="action toggleSettings content-actions__settings-button"
    :class="{ current: isOpen }"
    data-automation="menuitem-settings"
    @click="runAction"
  />
</template>

<script>
import {
  CLOSE_PANEL,
  OPEN_PANEL,
  SETTINGS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SettingsButton',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      isOpen: state => state.contentView.showSettingsPanel,
      isLogsVisible: state => state.contentView.showLogs,
    }),
  },
  methods: {
    ...mapActions({
      togglePanels: TOGGLE_PANELS,
    }),
    runAction() {
      if (this.isLogsVisible) {
        this.$router.push({
          name: 'apps.access',
          params: { idOrGuid: this.app.guid },
          replace: true,
        });
      }
      const togglePayload = {
        panel: SETTINGS_PANEL,
        action: this.isOpen ? CLOSE_PANEL : OPEN_PANEL,
      };
      this.togglePanels(togglePayload);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggleSettings {
    background-image: url(Images/elements/actionToggleSettings.svg);
  }
</style>
