<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <div
      v-if="!hasHelp"
      :class="{ small }"
      class="rs-field__help"
    >
      <label
        :for="name"
        :class="{ small }"
        class="rs-field__help-label"
      >
        {{ label }}
      </label>
    </div>

    <RSInformationToggle v-if="hasHelp">
      <template #title>
        <label
          :for="name"
          :class="{ small }"
          class="rs-field__help-label"
        >
          {{ label }}
        </label>
      </template>
      <template #help>
        <span v-if="$slots.help">
          <slot name="help" />
        </span>
        <span v-else>{{ help }}</span>
      </template>
    </RSInformationToggle>

    <div class="rs-field__control">
      <span
        v-if="showValue"
        class="current-value"
      >
        {{ value }}
      </span>
      <input
        v-bind="$attrs"
        :id="name"
        :aria-describedby="`${ name }-message`"
        :aria-label="label"
        :aria-invalid="hasError"
        :class="{ error: hasError, warning: hasWarning, info: hasInfo, small, 'show-value': showValue }"
        :max="max"
        :min="min"
        :name="name"
        :value="value"
        class="rs-input range"
        list="ticks"
        type="range"
        :step="step"
        @change="$emit('update:modelValue', Number($event.target.value))"
      >
      <datalist
        v-if="hasTicks"
        id="ticks"
        class="ticks"
      >
        <option
          v-for="tick in ticks"
          :key="tick.value"
          :value="tick.value"
          class="tick"
        />
      </datalist>
    </div>
    <div
      class="rs-field__range-values"
    >
      <div>{{ min }}</div>
      <div>{{ max }}</div>
    </div>
    <!-- message text -->
    <div
      v-if="hasMessage"
      :id="`${ name }-message`"
      :class="{ 'rs-field__error': hasError, 'rs-field__warning': hasWarning, 'rs-field__info': hasInfo }"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
const ErrorMessage = 'error';
const WarningMessage = 'warning';
const InfoMessage = 'info';

import RSInformationToggle from './RSInformationToggle.vue';

export default {
  name: 'RSInputRange',
  components: { RSInformationToggle },
  inheritAttrs: false,
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    help: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    messageType: {
      type: String,
      default: ErrorMessage
    },
    showValue: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      ticks: null,
    };
  },
  computed: {
    hasMessage() {
      return Boolean(this.message);
    },
    hasHelp() {
      return Boolean(this.help) || Boolean(this.$slots.help);
    },
    hasError() {
      return this.hasMessage && this.messageType === ErrorMessage;
    },
    hasWarning() {
      return this.hasMessage && this.messageType === WarningMessage;
    },
    hasInfo() {
      return this.hasMessage && this.messageType === InfoMessage;
    },
    valueOffset() {
      const range = this.max - this.min;
      return `${((this.value - this.min) / range * 100) }%`;
    },
    hasTicks() {
      return this.ticks && this.ticks.length > 0;
    }
  },
  beforeMount() {
    this.generateTicksFromStep();
  },
  methods: {
    generateTicksFromStep() {
      if (this.step <= 0) {
        return;
      }
      const stepsForRange = Math.ceil(this.max / this.step) + 1;

      this.ticks = Array.from(Array(stepsForRange)).map((_, index) => {
        const value = Math.min(this.max, (index) * this.step);

        return ({
          value,
          label: value,
        });
      });
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

input {
  box-sizing: border-box;
  padding: 5px 10px;
  &:disabled {
    @include control-disabled-input;
  }
  &[type=range] {
    padding: 5px 0;
  }
}

.rs-field {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }

  &__label {
    display: block;
    @include label;

    &.small {
      @include label(true);
    }
  }

  &__control {
    position: relative;
    display: flex;
    flex-direction: column;

    &.__show-value {
      margin-top: 20px;
    }

    & .rs-input {
      flex: 1;
    }

    .current-value {
      position: absolute;
      align-self:flex-end;
      top: -25px;
      border-radius: 3px;
      background-color: $color-selected;
      color: $color-white;
      padding: .2rem .3rem;
      font-size: .9rem;
    }
  }

  &__error {
    @include message;
    color: $color-error;
  }

  &__warning {
    @include message;
    color: $color-warning;
  }

  &__info {
    @include message;

    &-label {
      font-size: 0.9rem;
    }
  }

  &__help {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;

    &-label {
      @include label;

      &.small {
        @include label(true);
      }
    }
  }

  &__range-values {
    display: flex;
    justify-content: space-between;
    font-size: $rs-font-size-smaller;
    color: $color-secondary-inverse;
    margin: 0 0.3em;
  }
}

.rs-input {
  width: 100%;
  max-width: 100%;
  font-size: $rs-font-size-normal;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: $color-secondary-inverse;
  @include control-visible-focus;

  &.small {
    font-size: $rs-font-size-small;
    padding: 0.25rem 0.4rem;
  }

  &.range {
    padding: 0;
  }

  @include message-state;
}
</style>
